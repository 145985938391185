import React, {
  useState,
} from 'react';
import { Icon } from '@iconify/react/dist/iconify.js';
import Tooltip from '../Tooltip';
import { Slider } from '../ImageTransform';


interface LabelSliderProps {
  id: string;
  label: string;
  initValue: number;
  onValue: (value: number) => void;
}
export const LabelSlider = ({
  id,
  label,
  initValue,
  onValue,
}: LabelSliderProps): JSX.Element => {

  const [v, setV] = useState(initValue);

  function handleValue(value: number) {
    setV(value);
    onValue(value / 100);
  }

  return (
    <div key={id} className="flex">
      {/* <label htmlFor={`slider-${id}`} className="mr-2">
        {label}:
      </label>
      <span className="w-12">{v}%</span>
      <input
        id={`slider-${id}`}
        type="range"
        value={v}
        onChange={e => handleValue(parseInt(e.target.value))}
        className="flex-1"
      /> */}
      <Slider
        leftLabel={
          <div className="text-sm flex gap-x-[5px] items-center">
            <div>{label}</div>
            <Tooltip content="Use the slider to control the opacity of the layer(s) in the view above.">
              <div>
                <Icon icon="heroicons-outline:information-circle" className="w-[15px] h-[15px]" />
              </div>
            </Tooltip>
          </div>
        }
        valueRenderer={(value) => (
          <div className="w-full flex justify-end text-xs pt-0.5">

            <div className="flex gap-x-[3px]">
              <div
                style={{
                  opacity: Math.max(value, 0.2),
                }}
              >{`${value}%`}</div>
            </div>

          </div>
        )}
        min={0}
        max={100}
        step={1}
        value={v}
        setValue={(value) => handleValue(value)}
        onChange={(value) => {
          handleValue(value);
        }}
      />
    </div>
  );
};
