import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useContext,
} from "react";
import { unstable_batchedUpdates } from "react-dom";
import useWizardStore, {
} from "../stores/useWizardStore";
import { db } from "../../db";
import { useLiveQuery } from "dexie-react-hooks";
import "../../anims.css";

// 1. check local db
// 2. check server db
// 3. sync server db to local db

export function SyncImages({
  isBusy,
  onClickImport,
  onClickDate,
  onClickEditor,
}) {
  const getIsImagingReady = useWizardStore(state => state.getIsImagingReady);
  const allCaptureDates = useWizardStore(state => state.allCaptureDates);
  const selectedCaptureDate = useWizardStore(state => state.selectedCaptureDate);
  const setSelectedCaptureDate = useWizardStore(state => state.setSelectedCaptureDate);

  const images = useLiveQuery(() => db.metaWhereUser().toArray());

  const getImageTypePerEye = useCallback(
    (type: string) => {
      if (!images) return {};

      return {
        OD: images.filter((i) => i.type === type && i.eye === "OD").length,
        OS: images.filter((i) => i.type === type && i.eye === "OS").length,
      };
    },
    [images]
  );

  const FAFImages = useMemo(
    () => getImageTypePerEye("FAF"),
    [getImageTypePerEye]
  );
  const FAFONHImages = useMemo(
    () => getImageTypePerEye("FAFONH"),
    [getImageTypePerEye]
  );
  const OCTImages = useMemo(
    () => getImageTypePerEye("OCT"),
    [getImageTypePerEye]
  );
  const FAFProperties = useMemo(
    () => getImageTypePerEye("FAF Properties"),
    [getImageTypePerEye]
  );
  const FAFONHProperties = useMemo(
    () => getImageTypePerEye("FAFONH Properties"),
    [getImageTypePerEye]
  );
  const OCTProperties = useMemo(
    () => getImageTypePerEye("OCT Properties"),
    [getImageTypePerEye]
  );

  const canStimulusEditor = useMemo(() => {
    if (
      !FAFImages.OD ||
      !FAFImages.OS ||
      !OCTImages.OD ||
      !OCTImages.OS ||
      selectedCaptureDate == ""
    ) {
      return false;
    }

    return (
      getIsImagingReady('OD') &&
      FAFImages.OD > 0 &&
      FAFImages.OS > 0 &&
      OCTImages.OD > 0 &&
      OCTImages.OS > 0
    );
  }, [FAFImages, OCTImages, selectedCaptureDate]);

  if (isBusy)
    return (
      <div className="flex flex-col justify-center items-center gap-2 bg-gray-200 p-2 rounded-md min-h-[6rem]">
        <div className="text-gray-500 text-sm font-semibold">Loading</div>
        <div className="dotpulse-container">
          <div className="dotpulse" />
        </div>
      </div>
    );

  return (
    <div className="flex flex-col gap-y-4">
      <div className="grid grid-cols-4 gap-x-4">
        <div>OCT</div>
        <div>OD: {OCTImages.OD}</div> <div>OS: {OCTImages.OS}</div> <div>Properties: {`(OD ${OCTProperties.OD} + OS ${OCTProperties.OS})`}</div>
        <div>FAF-2</div>
        <div>OD: {FAFImages.OD}</div> <div>OS: {FAFImages.OS}</div> <div>Properties: {`(OD ${FAFProperties.OD} + OS ${FAFProperties.OS})`}</div>
        <div>FAF-1M</div>
        <div>OD: {FAFONHImages.OD}</div> <div>OS: {FAFONHImages.OS}</div> <div>Properties: {`(OD ${FAFONHProperties.OD} + OS ${FAFONHProperties.OS})`}</div>
      </div>
      <div className="flex justify-between">
        <button
          className={"bg-blue-500 text-white p-2 rounded-md" + (allCaptureDates.length < 1 ? '' : ' invisible')}
          onClick={onClickImport}
        >
          Import Images
        </button>
        {allCaptureDates.length > 0 && (
          <div>
            <select
              className="bg-blue-100 p-2 rounded-md"
              onChange={(e) => setSelectedCaptureDate(e.target.value)}
              value={selectedCaptureDate}
            >
              <option value="">Select Import Date</option>
              {allCaptureDates.map((cd) => (
                <option key={cd.toString()} value={cd.toString()}>
                  {cd.toString()}
                </option>
              ))}
            </select>
            <button
              className="bg-blue-500 text-white p-2 rounded-md disabled:opacity-50"
              disabled={!selectedCaptureDate}
              onClick={() => {
                if (!selectedCaptureDate) return;
                onClickDate();
              }}
            >
              Modify
            </button>
          </div>
        )}

        <button
          className="bg-blue-500 text-white p-2 rounded-md disabled:opacity-50"
          disabled={!canStimulusEditor}
          onClick={onClickEditor}
        >
          Proceed to Stimulus Editor
        </button>
      </div>
    </div>
  );
}
