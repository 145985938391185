import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useContext,
  useCallback,
} from "react";
import { unstable_batchedUpdates } from "react-dom";
import { db } from "../../db";
import { useLiveQuery } from "dexie-react-hooks";
import useWizardStore, {
  getDefaultTransform,
} from "../stores/useWizardStore";
import {
  ImageLookup,
  ImageRegistrationTransform,
  BScanData,
  LocalizerData,
  upgradeLocalizerData,
} from '../types/ImagingTypes';
import { MainContext } from "../contexts/MainContext";
import * as common from '../../common';
import { WebTools, ResponseError, HttpError } from "../../WebTools";
import {
  LayoutDB,
  parseLayout,
  loadImageConfig,
  LayoutRow,
  parseVVPResult,
  LocUpdate,
  Coord,
  flipLayout,
  EditAction,
  prepareHomeCSV,
  serializeCSV,
  getVascularIDs,
  prepareCSV,
  applyEdits,
} from "../types/LayoutTypes";

import { SyncImages } from "../components/SyncImages";
import { UploadImagesWithPreview } from "../components/UploadImagesWithPreview";
import ProcessImages from "../components/ProcessImages";
import { ImageTransform } from "../components/ImageTransform";
import { OCTImages } from "../../components/Imaging/OCTImages";
import { StudyEyeSelector } from "../components/StudyEyeSelector";
import { getLocalizerSteps, getTrainingSteps, LayoutEditor } from "../components/svg/LayoutEditor";
import { HomeEditor } from "../components/svg/HomeEditor";
import { FinishPage } from "../components/FinishPage";
import { Modal, useModal } from "../components/Modal";
import html2canvas from 'html2canvas';
import { ReviewLayout } from "../components/svg/ReviewLayout";

const API = 'https://www.seevividly.com/api_localizer/';

export function Wizard({
  isHome,
}) {
  const { u, p, patient_id, crc_name, session_uuid } = useContext(MainContext)!;

  const [trainingTemplate, setTrainingTemplate] = useState<LayoutRow[]>([]);
  const [localizerTemplate, setLocalizerTemplate] = useState<LayoutRow[]>([]);
  const [homeTemplate, setHomeTemplate] = useState<LayoutRow[]>([]);
  const templateEye = useRef<'OD' | 'OS'>('OD');

  const trainingCSV = useWizardStore(state => state.trainingCSV);
  const localizerCSV = useWizardStore(state => state.localizerCSV);
  const setTrainingCSV = useWizardStore(state => state.setTrainingCSV);
  const setLocalizerCSV = useWizardStore(state => state.setLocalizerCSV);
  const setHomeCSV = useWizardStore(state => state.setHomeCSV);
  const setLocalizerResult = useWizardStore(state => state.setLocalizerResult);

  const bscOD = useRef<Coord>();
  const bscOS = useRef<Coord>();
  const setTrainingEdits = useWizardStore(state => state.setTrainingEdits);
  const setLocalizerEdits = useWizardStore(state => state.setLocalizerEdits);
  const setVascularCoords = useWizardStore(state => state.setVascularCoords);
  const prl = useWizardStore(state => state.prl);
  const cyclo = useWizardStore(state => state.cyclo);
  const homeLocsOD = useRef<LocUpdate[]>();
  const homeLocsOS = useRef<LocUpdate[]>();

  const setStudyEye = useWizardStore(state => state.setStudyEye);
  const setAllCaptureDates = useWizardStore(state => state.setAllCaptureDates);
  const setImageConfig = useWizardStore(state => state.setImageConfig);
  const setImageRegistrations = useWizardStore(state => state.setImageRegistrations);
  const setOCTFovealPit = useWizardStore(state => state.setOCTFovealPit);
  const getIsImagingReady = useWizardStore(state => state.getIsImagingReady);

  const studyEye = useWizardStore(state => state.studyEye);
  const selectedCaptureDate = useWizardStore(state => state.selectedCaptureDate);
  const setSelectedCaptureDate = useWizardStore(state => state.setSelectedCaptureDate);
  const importCaptureDate = useWizardStore(state => state.importCaptureDate);

  const buildLocalizerData = useWizardStore(state => state.buildLocalizerData);
  const setRegistration = useWizardStore(state => state.setRegistration);
  const regFAF_OD = useRef<ImageRegistrationTransform>();
  const regFAFONH_OD = useRef<ImageRegistrationTransform>();
  const regFAF_OS = useRef<ImageRegistrationTransform>();
  const regFAFONH_OS = useRef<ImageRegistrationTransform>();
  const bscanOD = useRef<BScanData>();
  const bscanOS = useRef<BScanData>();

  const stepsStack = useRef<number[]>([]);
  const [step, setStep] = useState<number>(0);
  const [canNextStep, setCanNextStep] = useState(true);
  const [isBusy, setIsBusy] = useState<boolean>(true);

  const order = isHome
  ? [
    'homeOD',
    'homeOS',
    'finishHome',
  ]
  : [
    'selectImageSet',
    'importImages',
    'processImages',
    'selectStudyEye',
    'registerOD',
    'bscanOD',
    'trainingOD',
    'localizerOD',
    'registerOS',
    'bscanOS',
    'trainingOS',
    'localizerOS',
    'finishLocalizer',
  ];

  // mount
  useEffect(() => {
    // loadDev();
    console.log("fetching localizer data on mount");
    const abort = new AbortController();
    (async () => {
      const [
        mydb,
        vivid,
      ] = await Promise.all([
        loadDB(),
        pullVivid(abort),
      ]);
      let locData = mydb.locData;
      if (vivid.trainingLayoutDB?.localizer_data) {
        locData = upgradeLocalizerData(vivid.trainingLayoutDB.localizer_data);
        templateEye.current = locData.studyEye!;
      }
      if (vivid.localizerLayoutDB) {
      }
      await initState(
        mydb.dates,
        vivid.trainingTemplateDB.csv,
        vivid.localizerTemplateDB.csv,
        vivid.homeTemplateDB.csv,
        vivid.trainingLayoutDB?.csv,
        vivid.localizerLayoutDB?.csv,
        vivid.localizerResult,
        locData,
        vivid.localizerLayoutDB?.metadata.VascularIDs,
        vivid.localizerLayoutDB?.metadata.VascularLocations,
      );
    })();
    return () => {
      abort.abort("abort fetch on dismount");
    }
  }, []);

  async function initState(
    captureDates: string[],
    trainingTemplate: string,
    localizerTemplate: string,
    homeTemplate: string,
    trainingLayout: string | undefined,
    localizerLayout: string | undefined,
    localizerResult: string | undefined,
    locData: LocalizerData | undefined,
    vascularIDs: number[] | undefined,
    vascularCoords: Coord[] | undefined,
  ) {
    // base templates
    let trainingRows = parseLayout(trainingTemplate);
    let localizerRows = parseLayout(localizerTemplate);
    let homeRows = parseLayout(homeTemplate);
    // saved layouts (home derived from localizer result)
    const trainingSaved = trainingLayout && parseLayout(trainingLayout);
    const localizerSaved = localizerLayout && parseLayout(localizerLayout);
    const resultRows = localizerResult && parseVVPResult(localizerResult);
    if (locData && locData.studyEye && locData.studyEye == 'OS') {
      templateEye.current = 'OS';
      trainingRows = flipLayout(trainingRows);
      localizerRows = flipLayout(localizerRows);
      homeRows = flipLayout(homeRows);
    }
    let vasc: Coord[] | undefined = undefined;
    if (isHome && vascularIDs && vascularCoords) {
      const vr = vascularIDs.map(id => (localizerSaved! as LayoutRow[]).find(r => r.stimulus_location_id == id)!);
      vasc = [...vascularCoords, ...vr.map(r => ({ x: r.stimulus_x_deg, y: r.stimulus_y_deg }))];
    }
    unstable_batchedUpdates(() => {
      setAllCaptureDates(captureDates);
      setTrainingTemplate(trainingRows);
      setLocalizerTemplate(localizerRows);
      setHomeTemplate(homeRows);
      setTrainingCSV(trainingRows);
      setLocalizerCSV(localizerRows);
      setHomeCSV(homeRows);
      if (resultRows) setLocalizerResult(resultRows);
      if (locData) {
        if (locData.studyEye)
          setStudyEye(locData.studyEye);
        if (locData.registrations)
          setImageRegistrations(locData.registrations);
        if (locData.fovealPit) {
          if (locData.fovealPit.OD) setOCTFovealPit('OD', locData.fovealPit.OD);
          if (locData.fovealPit.OS) setOCTFovealPit('OS', locData.fovealPit.OS);
        }
        setSelectedCaptureDate(captureDates.length < 1 ? '' : captureDates[captureDates.length-1]);
      }
      if (vasc) setVascularCoords(templateEye.current, vasc);
      setIsBusy(false);
    });
    if (isHome) {
      setTimeout(() => prepOverlay('OD'), 300);
      setTimeout(() => prepOverlay('OS'), 300);
    }
  }

  async function loadDB() {
    const [
      dates,
      locStateRow,
    ] = await Promise.all([
      db.getCaptureDates() as Promise<string[]>,
      db.locStateWhereUser().first(),
    ]);
    dates.sort();
    let locData: LocalizerData | undefined = undefined;
    if (locStateRow) {
      locData = locStateRow.json as LocalizerData;
      if (!locData.timestamp)
        locData.timestamp = Math.trunc(Date.now() / 1000);
      if (!locData.captureDate)
        locData.captureDate = dates[dates.length-1];
    }
    else {
      // need full sync
    }
    return {
      dates,
      locData,
    };
  }

  async function pullVivid(abort: AbortController) {
    const args = {
      u: u,
      p: p,
      patient_id: patient_id,
    };
    let templates: LayoutDB[];
    let saved: { [key:string]: LayoutDB | null };
    let locResult: { csv: string } | null;
    // try {
      ([templates, saved, locResult] = await Promise.all([
        WebTools.apiPost<typeof templates>(API+'get_layout_templates', args, abort.signal),
        WebTools.apiPost<typeof saved>(API+'get_saved_layouts', args, abort.signal),
        WebTools.apiPost<typeof locResult>(API+'get_localizer_result', args, abort.signal),
      ]));
    // } catch (e) {
    //   console.error('Error fetching layouts from server: ', e);
    //   return;
    // }
    return {
      trainingTemplateDB: templates.find(t => t.name.includes('Training'))!,
      localizerTemplateDB: templates.find(t => t.name.includes('Localizer'))!,
      homeTemplateDB: templates.find(t => t.name.includes('Home'))!,
      trainingLayoutDB: saved['Training'],
      localizerLayoutDB: saved['Localizer'],
      homeLayoutDB: saved['Home'],
      localizerResult: locResult?.csv,
    };
  }

  async function loadDev() {
    const [
      trainingTemplate,
      localizerTemplate,
      localizerLayout,
      localizerResult,
    ] = await Promise.all([
      fetch(process.env.PUBLIC_URL + '/Training_2326.csv').then(r => r.text()),
      fetch(process.env.PUBLIC_URL + '/Localizer_2326.csv').then(r => r.text()),
      fetch(process.env.PUBLIC_URL + '/sample_layout.csv').then(r => r.text()),
      fetch(process.env.PUBLIC_URL + '/sample_localizer.csv').then(r => r.text()),
    ]);
    return {
      trainingTemplate,
      localizerTemplate,
      localizerLayout,
      localizerResult,
    };
  }

  async function prepOverlay(eye: 'OD' | 'OS') {
    if (!getIsImagingReady(eye)) return;
    const cfg = await loadImageConfig(eye);
    unstable_batchedUpdates(() => {
      setImageConfig(eye, cfg);
    });
  }

  function saveReg(eye: 'OD' | 'OS') {
    const faf = eye == 'OD' ? regFAF_OD.current : regFAF_OS.current;
    const fafonh = eye == 'OD' ? regFAFONH_OD.current : regFAFONH_OS.current;
    setRegistration('FAF', eye, faf ?? getDefaultTransform());
    setRegistration('FAFONH', eye, fafonh ?? getDefaultTransform());
    db.localizer_state.put({ clinic_user_id: db.cachedUser, json: buildLocalizerData() });
  }

  function saveBScan(eye: 'OD' | 'OS') {
    const bscan = eye == 'OD' ? bscanOD.current : bscanOS.current;
    setOCTFovealPit(eye, bscan!);
    db.localizer_state.put({ clinic_user_id: db.cachedUser, json: buildLocalizerData() });
  }

  function saveStudyEye() {
    if (studyEye != templateEye.current) {
      setTrainingTemplate(flipLayout(trainingTemplate));
      setLocalizerTemplate(flipLayout(localizerTemplate));
      setHomeTemplate(flipLayout(homeTemplate));
      setTrainingCSV(flipLayout(trainingCSV));
      setLocalizerCSV(flipLayout(localizerCSV));
      templateEye.current = studyEye!;
    }
    db.localizer_state.put({ clinic_user_id: db.cachedUser, json: buildLocalizerData() });
  }


  function pushStep(s: number) {
    const stepName = order[step];
    const nextName = order[s];
    if (nextName.startsWith('training')
      || nextName.startsWith('localizer')
      || nextName.startsWith('home')
      || nextName.startsWith('finish')) {
      setCanNextStep(false);
    }
    else if (nextName.startsWith('register')) {
      setCanNextStep(true);
    }
    stepsStack.current.push(step);
    setStep(s);
  }

  async function nextStep() {
    await postScreenShot('next');
    const stepName = order[step];
    const nextName = order[step + 1];
    if (stepName == 'registerOD') {
      saveReg('OD');
    }
    else if (stepName == 'registerOS') {
      saveReg('OS');
    }
    else if (stepName == 'bscanOD') {
      saveBScan('OD');
    }
    else if (stepName == 'bscanOS') {
      saveBScan('OS');
    }
    else if (stepName == 'selectStudyEye') {
      saveStudyEye();
    }
  
    if (nextName == 'trainingOD')
      prepOverlay('OD');
    else if (nextName == 'trainingOS')
      prepOverlay('OS');
    if (['trainingOD', 'trainingOS'].includes(nextName))
      onEyeModalOpen();
    pushStep(step + 1);
  }

  async function prevStep() {
    await postScreenShot('back');
    const stepName = order[step];
    if (stepName == 'selectStudyEye')
      setStudyEye(templateEye.current);
    let s = stepsStack.current.pop() || 0;
    if (s == order.indexOf('processImages')) {
      s = 0;
      stepsStack.current = [];
    }
    setStep(s);
  }

  const componentRef = useRef<HTMLDivElement>(null);

  async function postScreenShot(event: string) {
    if (!componentRef.current) return;

    const screenshot = await html2canvas(document.getElementById("root")!, {
      scale: 2,
      onclone: async function (document, element) {
        // console.log('cloning:', document, element);

        const imageLoadPromises: Promise<void>[] = [];

        const fixSvgs = async () => {
          // fix svg rendering
          // if an svg has any image elements, check the href and if it's a blob, convert to base64
          const svgs = document.querySelectorAll("svg");
          console.log("found svgs:", svgs);
          svgs.forEach((svg) => {
            const imgs = svg.querySelectorAll("image");
            console.log("found images:", imgs);
            imgs.forEach((img) => {
              const href = img.getAttribute("href");
              console.log("found href:", href);
              if (href && href.startsWith("blob:")) {
                console.log("converting to base64");
                // convert to base64
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");
                const image = new Image();
                image.src = href;

                const imageLoadPromise = new Promise<void>(
                  (resolve, reject) => {
                    image.onload = () => {
                      canvas.width = image.width;
                      canvas.height = image.height;
                      ctx?.drawImage(image, 0, 0);
                      const dataURL = canvas.toDataURL("image/png");
                      img.setAttribute("href", dataURL);
                      img.setAttribute("crossorigin", "");
                      console.log("converted to base64:");
                      console.log(`${dataURL}`);
                      resolve();
                    };
                    image.onerror = () => {
                      console.error("error loading image");
                      reject();
                    };
                  }
                );

                imageLoadPromises.push(imageLoadPromise);
              }
            });
          });
          await Promise.all(imageLoadPromises);
        };
        await fixSvgs();
      },
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      return imgData.replace(/^data:image\/png;base64,/, "");
    });

    const metadata = {
      session_uuid,
      crc_name,
      step,
      step_name: order[step],
      user_agent: navigator.userAgent,
      event,
      source_url: window.location.href,
      timestamp: common.generalDateTime(),
    };

    const payload = {
      u,
      p,
      patient_id,
      metadata,
      screenshot,
    };

    // download screenshot locally
    // const a = document.createElement("a");
    // a.href = "data:image/png;base64," + screenshot;
    // a.download = "screenshot.png";
    // a.click();

    return WebTools.apiPost<{ name: string }>(
      API + "save_step_screenshot",
      payload
    );
  }

  function handleForward() {
    // getComponentScreenShot();
    nextStep();
  }
  
  function handleBack() {
    // getComponentScreenShot();
    if (
      isHome ||
      [
        "trainingOD",
        "trainingOS",
        "localizerOD",
        "localizerOS",
      ].includes(order[step])
    ) {
      onBackModalOpen();
    } else {
      prevStep();
    }
  }

  function handleSyncImages_ClickImport() {
    handleForward();
  }

  function handleSyncImages_ClickDate() {
    pushStep(order.indexOf('selectStudyEye'));
  }

  function handleSyncImages_ClickEditor() {
    prepOverlay('OD');
    pushStep(order.indexOf('trainingOD'));
    onEyeModalOpen();
  }

  function handleProcessImages_Finished() {
    setSelectedCaptureDate(importCaptureDate);
    nextStep();
  }

  function handleSelectStudy_Click(eye: 'OD' | 'OS' | '') {
    setCanNextStep(eye != '');
  }

  const handleEditor_Complete = useCallback((isComplete: boolean, edits?: EditAction[]) => {
    setCanNextStep(isComplete);
    if (edits) {
      const stepName = order[step];
      if (stepName == 'trainingOD') {
        bscOD.current = edits.find(e => e.arrayType == 'BS-C')?.action.translation;
        setTrainingEdits('OD', edits);
      }
      else if (stepName == 'trainingOS') {
        bscOS.current = edits.find(e => e.arrayType == 'BS-C')?.action.translation;
        setTrainingEdits('OS', edits);
      }
      else if (stepName == 'localizerOD')
        setLocalizerEdits('OD', edits);
      else if (stepName == 'localizerOS')
        setLocalizerEdits('OS', edits);
    }
  }, [step]);

  const handleHome_Complete = useCallback((isComplete: boolean, locs?: LocUpdate[]) => {
    setCanNextStep(isComplete);
    if (locs) {
      const stepName = order[step];
      if (stepName == 'homeOD') {
        homeLocsOD.current = locs;
      }
      else if (stepName == 'homeOS') {
        homeLocsOS.current = locs;
      }
    }
  }, [step]);

  function handleSessionA_Finish() {
    setIsBusy(true);
    (async () => {
      await postScreenShot("finishA");
      const tr = processTrainingLayout();
      const ld = buildLocalizerData();
      const pbodyTR = {
        u,
        p,
        patient_id,
        layout: {
          type: 'Training',
          csv: tr,
          locData: ld,
          metaFields: {
            EditorUsername: crc_name,
          },
        },
      };
      const rTrain = await WebTools.apiPost<{name:string}>(API+'save_layout', pbodyTR);
      console.log('layout saved:', rTrain.name);
      const lo = processLocalizerLayout();
      const pbodyLO = {
        u,
        p,
        patient_id,
        layout: {
          type: 'Localizer',
          csv: lo.csv,
          locData: ld,
          metaFields: {
            VascularIDs: lo.vascIDs,
            VascularLocations: lo.vascLocs,
            EditorUsername: crc_name,
          },
        },
      };
      const rLocal = await WebTools.apiPost<{name:string}>(API+'save_layout', pbodyLO);
      console.log('layout saved:', rLocal.name);
      unstable_batchedUpdates(() => {
        setIsBusy(false);
      });
      window.parent.postMessage('closeModal', '*');
    })();
  }

  function processTrainingLayout() {
    const store = useWizardStore.getState();
    const uod = applyEdits('OD', store.trainingEdits.OD, store.trainingCSV);
    const uos = applyEdits('OS', store.trainingEdits.OS, store.trainingCSV);
    const rows = prepareCSV(store.trainingCSV, [...uod, ...uos]);
    return serializeCSV(rows);
  }

  function processLocalizerLayout() {
    const store = useWizardStore.getState();
    const uod = applyEdits('OD', store.localizerEdits.OD, store.localizerCSV);
    const uos = applyEdits('OS', store.localizerEdits.OS, store.localizerCSV);
    const rowsOD = prepareCSV(store.localizerCSV.filter(r => r.stimulus_eye == 'right'), uod);
    const rowsOS = prepareCSV(store.localizerCSV.filter(r => r.stimulus_eye == 'left'), uos);
    // tag bloodies
    const vod = getVascularIDs(rowsOD, store.vascularCoords.OD);
    const vos = getVascularIDs(rowsOS, store.vascularCoords.OS);
    return {
      csv: serializeCSV([...rowsOD, ...rowsOS]),
      vascIDs: [...vod.ids, ...vos.ids],
      vascLocs: [...vod.remains, ...vos.remains],
    };
  }

  function handleSessionB_Finish() {
    setIsBusy(true);
    (async () => {
      await postScreenShot("finishB");
      const h = processHomeLayout();
      const ld = buildLocalizerData();
      const pbody = {
        u,
        p,
        patient_id,
        layout: {
          type: 'Home',
          csv: h.csv,
          locData: ld,
          metaFields: {
            VascularIDs: h.vascIDs,
            EstFixationOffset: {
              OD: { x: h.prl.OD.x, y: h.prl.OD.y, cyclo: h.cyclo.OD },
              OS: { x: h.prl.OS.x, y: h.prl.OS.y, cyclo: h.cyclo.OS },
            },
            EditorUsername: crc_name,
          },
        },
      };
      const r = await WebTools.apiPost<{name:string}>(API+'save_layout', pbody);
      console.log('layout saved:', r.name);
      unstable_batchedUpdates(() => {
        setIsBusy(false);
      });
      window.parent.postMessage('closeModal', '*');
    })();
  }

  function processHomeLayout() {
    const store = useWizardStore.getState();
    // editor generates the spindles
    // combine with base template
    const rowsOD = prepareHomeCSV(homeTemplate.filter(r => r.stimulus_eye == 'right'), homeLocsOD.current!);
    const rowsOS = prepareHomeCSV(homeTemplate.filter(r => r.stimulus_eye == 'left'), homeLocsOS.current!);
    // tag vessels
    const vod = getVascularIDs(rowsOD, store.vascularCoords.OD);
    const vos = getVascularIDs(rowsOS, store.vascularCoords.OS);
    // re-tag edited arrays
    const editedOD = homeLocsOD.current!.filter(u => u.hasOwnProperty('isMarked'));
    const editedOS = homeLocsOS.current!.filter(u => u.hasOwnProperty('isMarked'));
    for (const e of editedOD) {
      if (e.isMarked) {
        if (vod.ids.includes(e.id))
          continue;
        else vod.ids.push(e.id);
      }
      else if (vod.ids.includes(e.id))
        vod.ids = vod.ids.filter(id => id != e.id);
    }
    for (const e of editedOS) {
      if (e.isMarked) {
        if (vos.ids.includes(e.id))
          continue;
        else vos.ids.push(e.id);
      }
      else if (vos.ids.includes(e.id))
        vos.ids = vos.ids.filter(id => id != e.id);
    }
    // apply transform
    const mxOD = getMatrix(store.prl.OD, store.cyclo.OD);
    const mxOS = getMatrix(store.prl.OS, store.cyclo.OS);
    // write xy in place
    if (!mxOD.isIdentity)
      applyMatrix(mxOD, rowsOD);
    if (!mxOS.isIdentity)
      applyMatrix(mxOS, rowsOS);
    return {
      csv: serializeCSV([...rowsOD, ...rowsOS]),
      vascIDs: [...vod.ids, ...vos.ids],
      prl: store.prl,
      cyclo: store.cyclo,
    };
  }

  function handleReg_Transform(eye: string, imgType: string, t: ImageRegistrationTransform) {
    if (eye == 'OD') {
      if (imgType == 'FAF') regFAF_OD.current = t;
      else if (imgType == 'FAFONH') regFAFONH_OD.current = t;
    }
    else {
      if (imgType == 'FAF') regFAF_OS.current = t;
      else if (imgType == 'FAFONH') regFAFONH_OS.current = t;
    }
  }

  function handleBScan_Calc(eye: string, data: BScanData) {
    if (eye == 'OD') bscanOD.current = data;
    else bscanOS.current = data;
  }




  function getStepConfig(): StepConfig {
    const studyTextOD = `${studyEye == 'OD' ? '' : 'Non-'}Study Eye`;
    const studyTextOS = `${studyEye == 'OS' ? '' : 'Non-'}Study Eye`;
    switch (order[step]) {
    case 'selectImageSet':
      return {
        title: 'A. Select Image Set',
        subTitle: 'Select previously imported images by capture date. Then either edit registrations or proceed to Test Configuration.',
        component: <SyncImages
          key={order[step]}
          isBusy={isBusy}
          onClickImport={handleSyncImages_ClickImport}
          onClickDate={handleSyncImages_ClickDate}
          onClickEditor={handleSyncImages_ClickEditor}
          />,
      };
    case 'importImages':
      return {
        title: 'A. Import Images',
        subTitle: 'Import image files from your computer.',
        component: <UploadImagesWithPreview key={order[step]} onDropReady={b => setCanNextStep(b)} />,
      };
    case 'processImages':
      return {
        title: '',
        subTitle: 'Processing imported images...',
        component: <ProcessImages key={order[step]} onFinished={handleProcessImages_Finished} />,
      };
    case 'bscanOD':
      return {
        title: 'A. Mark Foveal Center Point: OD',
        subTitle: 'Estimate the foveal center point in one of the B-scans. Click a location along the B-scan horizontal axis.',
        component: (
          <OCTImages
            key={order[step]}
            eye='OD'
            type='OCT'
            capture_date={selectedCaptureDate}
            onCalc={b => handleBScan_Calc('OD', b)}
          />
        ),
      };
    case 'bscanOS':
      return {
        title: 'A. Mark Foveal Center Point: OS',
        subTitle: 'Estimate the foveal center point in one of the B-scans. Click a location along the B-scan horizontal axis.',
        component: (
          <OCTImages
            key={order[step]}
            eye='OS'
            type='OCT'
            capture_date={selectedCaptureDate}
            onCalc={b => handleBScan_Calc('OS', b)}
          />
        ),
      };
    case 'registerOD':
      return {
        title: 'A. Register Images: OD',
        subTitle: 'Align each FAF image to the IR image.',
        component: (
          <RegistrationComponent
            key={order[step]}
            eye='OD'
            capture_date={selectedCaptureDate}
            onFAFTransform={t => handleReg_Transform('OD', 'FAF', t)}
            onFAFONHTransform={t => handleReg_Transform('OD', 'FAFONH', t)}
          />
        ),
      };
    case 'registerOS':
      return {
        title: 'A. Register Images: OS',
        subTitle: 'Align each FAF image to the IR image.',
        component: (
          <RegistrationComponent
            key={order[step]}
            eye='OS'
            capture_date={selectedCaptureDate}
            onFAFTransform={t => handleReg_Transform('OS', 'FAF', t)}
            onFAFONHTransform={t => handleReg_Transform('OS', 'FAFONH', t)}
          />
        ),
      };
    case 'selectStudyEye':
      return {
        title: 'A. Select Study Eye',
        subTitle: 'The study eye will receive GA stimulus arrays.',
        component: <StudyEyeSelector key={order[step]} onSelect={handleSelectStudy_Click} />,
      };
    case 'trainingOD':
      return {
        title: `A. Design Training Test: OD (${studyTextOD})`,
        subTitle: '',
        component: (
          <LayoutEditor key={order[step]} eye='OD' steps={getTrainingSteps()} csvRows={trainingCSV} onComplete={handleEditor_Complete} />
        ),
      };
    case 'trainingOS':
      return {
        title: `A. Design Training Test: OS (${studyTextOS})`,
        subTitle: '',
        component: (
          <LayoutEditor key={order[step]} eye='OS' steps={getTrainingSteps()} csvRows={trainingCSV} onComplete={handleEditor_Complete} />
        ),
      };
    case 'localizerOD':
      return {
        title: `A. Design Localizer Test: OD (${studyTextOD})`,
        subTitle: '',
        component: (
          <LayoutEditor key={order[step]} eye='OD' steps={getLocalizerSteps(studyEye == 'OD')} csvRows={localizerCSV} bsc={bscOD.current} onComplete={handleEditor_Complete} />
        ),
      }
    case 'localizerOS':
      return {
        title: `A. Design Localizer Test: OS (${studyTextOS})`,
        subTitle: '',
        component: (
          <LayoutEditor key={order[step]} eye='OS' steps={getLocalizerSteps(studyEye == 'OS')} csvRows={localizerCSV} bsc={bscOS.current} onComplete={handleEditor_Complete} />
        ),
      };
    case 'finishLocalizer':
      const processedT = processTrainingLayout();
      const processedL = processLocalizerLayout();
      return {
        title: `A. Save All Changes`,
        subTitle: '',
        component: (
          <FinishPage key={order[step]} layout="Localizer" isBusy={isBusy} onConfirm={handleSessionA_Finish}>
            <div className='font-bold text-blue-700 mt-4'>Training Test Layout</div>
            <ReviewLayout key='review-training' csv={parseLayout(processedT)} />
            <div className='font-bold text-blue-700 mt-2'>Localizer Test Layout</div>
            <ReviewLayout key='review-localizer' csv={parseLayout(processedL.csv)} marked={processedL.vascIDs} />
          </FinishPage>
        ),
      };
    case 'homeOD':
      return {
        title: `B. Design Home Test: OD (${studyTextOD})`,
        subTitle: '',
        component: (
          <HomeEditor key={order[step]} eye='OD' templateCSV={homeTemplate} enableCyclo={studyEye == 'OD'} onComplete={handleHome_Complete} />
        ),
      };
    case 'homeOS':
      return {
        title: `B. Design Home Test: OS (${studyTextOS})`,
        subTitle: '',
        component: (
          <HomeEditor key={order[step]} eye='OS' templateCSV={homeTemplate} enableCyclo={studyEye == 'OS'} onComplete={handleHome_Complete} />
        ),
      };
    case 'finishHome':
      const processed = processHomeLayout();
      return {
        title: `B. Save All Changes`,
        subTitle: '',
        component: (
          <FinishPage key={order[step]} layout="Home" isBusy={isBusy} onConfirm={handleSessionB_Finish}>
            <div className='font-bold text-blue-700 mt-4'>Home Test Layout</div>
            <ReviewLayout key='review-home' csv={parseLayout(processed.csv)} marked={processed.vascIDs} prl={prl} cyclo={cyclo} />
          </FinishPage>
        ),
      };
    }
    return { title: 'Unknown', subTitle: '', component: <div></div>};
  }

  const stepCfg = getStepConfig()!;
  const hideNav = order[step] == 'selectImageSet'
    || order[step] == 'processImages';
  const showNext = !hideNav && step < order.length - 1;
  const showBack = !hideNav && step > 0;
  const { isOpen:isBackModalOpen, onOpen:onBackModalOpen, onClose:onBackModalClose } = useModal();
  const { isOpen:isEyeModalOpen, onOpen:onEyeModalOpen, onClose:onEyeModalClose } = useModal();
  const currentEye = order[step].endsWith('OD') ? 'OD' : 'OS';
  const studyText = `${currentEye == studyEye ? '' : 'Non-'}Study Eye`;

  return (
    <div className="flex flex-col gap-y-4 h-full">
      <div className="font-bold text-blue-700 ">{stepCfg.title}</div>
      <div className="text-xs text-gray-800">{stepCfg.subTitle}</div>
      <div className="flex-1" ref={componentRef}>
        {stepCfg.component}
      </div>
      <div className="flex justify-between">
        {showBack ? (
        <button
          className="bg-blue-500 text-white p-2 rounded-md"
          onClick={handleBack}
        >
          Back
        </button>
        ) : <div/>}
        {showNext ? (
        <button
          className="bg-blue-500 text-white p-2 rounded-md disabled:opacity-50"
          disabled={!canNextStep}
          onClick={handleForward}
        >
          Next
        </button>
        ) : <div/>}
      </div>
      <Modal isOpen={isBackModalOpen} onClose={onBackModalClose} title="">
        <p>Are you sure you want to go back? The modifications made at the current screen will be lost.</p>
        <div className="flex items-center justify-between">
          <button
            className="bg-red-500 text-white px-4 py-2 rounded-md"
            onClick={() => {onBackModalClose(); prevStep();}}
          >
            Yes
          </button>
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded-md"
            onClick={onBackModalClose}
          >
            No
          </button>
        </div>
      </Modal>
      <Modal isOpen={isEyeModalOpen} onClose={onEyeModalClose} title="">
        <p>{`Now designing layout for ${currentEye}. Please confirm: Is ${currentEye} the ${studyText}?`}</p>
        <div className="flex items-center justify-between">
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded-md"
            onClick={onEyeModalClose}
          >
            Yes
          </button>
          <button
            className="bg-red-500 text-white px-4 py-2 rounded-md"
            onClick={() => { onEyeModalClose(); stepsStack.current = []; setStep(order.indexOf('selectStudyEye')); }}
          >
            No
          </button>
        </div>
      </Modal>
    </div>
  );
}



interface StepConfig {
  title: string;
  subTitle: string;
  component: JSX.Element;
}

function getOtherEye(eye: 'OD' | 'OS' | null) {
  if (eye == 'OD') return 'OS';
  if (eye == 'OS') return 'OD';
  return null;
}

function getMatrix(t: Coord, r: number) {
  const m = new DOMMatrix();
  if (t.x != 0 || t.y != 0)
    m.translateSelf(t.x, t.y);
  if (r != 0)
    m.rotateSelf(r);
  return m;
}

function applyMatrix(m: DOMMatrix, rows: LayoutRow[]) {
  for (const r of rows) {
    const p = m.transformPoint(new DOMPoint(r.stimulus_x_deg, r.stimulus_y_deg));
    r.stimulus_x_deg = p.x;
    r.stimulus_y_deg = p.y;
  }
}




const FAFONHRegistrationComponent = ({
  eye,
  capture_date,
  onTransform,
}: {
  eye: "OD" | "OS";
  capture_date: string;
  onTransform: (ImageRegistrationTransform) => void;
}) => {
  // const setFixedLookup = useWizardStore(state => state.setFixedLookup);
  const FAFONHRegistrationImage = useLiveQuery(async () => {
    const rows = await db.metaWhereUser()
      .filter((row) =>
        row.type === "FAFONH"
        && row.eye === eye
        && row.capture_date === capture_date)
      .toArray();
    if (rows.length === 0) return null;

    return rows[0];
  }, [eye, capture_date]);

  const OCTRegistrationImage = useLiveQuery(async () => {
    const rows = await db.metaWhereUser()
      .filter((row) =>
        row.type === "OCT"
        && row.eye === eye
        && row.capture_date === capture_date)
      .toArray();
    if (rows.length === 0) return null;

    return rows[0];
  }, [eye, capture_date]);

  if (
    FAFONHRegistrationImage === undefined ||
    OCTRegistrationImage === undefined
  )
    return <div>Loading...</div>;

  if (FAFONHRegistrationImage === null || OCTRegistrationImage === null)
    return <div>Error: No images found</div>;

  if (!FAFONHRegistrationImage.filepath || !OCTRegistrationImage.filepath)
    return <div>Error: No images found - missing filepath(s)</div>;

  const fixedLookup: ImageLookup = {
    filepath: OCTRegistrationImage.filepath,
    subImageKey: "RED",
    eye,
    type: "OCT",
  };
  const unfixedLookup: ImageLookup = {
    filepath: FAFONHRegistrationImage.filepath,
    subImageKey: "FAF",
    eye,
    type: "FAFONH",
  };
  // setFixedLookup(eye, fixedLookup);

  return (
    <ImageTransform
      fixed={fixedLookup}
      unfixed={unfixedLookup}
      onTransform={onTransform}
    />
  );
};

const FAFRegistrationComponent = ({
  eye,
  capture_date,
  onTransform,
}: {
  eye: "OD" | "OS";
  capture_date: string;
  onTransform: (ImageRegistrationTransform) => void;
}) => {
  // const setFixedLookup = useWizardStore(state => state.setFixedLookup);
  const FAFRegistrationImage = useLiveQuery(async () => {
    const rows = await db.metaWhereUser()
      .filter((row) =>
        row.type === "FAF"
        && row.eye === eye
        && row.capture_date === capture_date)
      .toArray();
    if (rows.length === 0) return null;

    return rows[0];
  }, [eye, capture_date]);

  const OCTRegistrationImage = useLiveQuery(async () => {
    const rows = await db.metaWhereUser()
      .filter((row) =>
        row.type === "OCT"
        && row.eye === eye
        && row.capture_date === capture_date)
      .toArray();
    if (rows.length === 0) return null;

    return rows[0];
  }, [eye, capture_date]);

  if (FAFRegistrationImage === undefined || OCTRegistrationImage === undefined)
    return <div>Loading...</div>;

  if (FAFRegistrationImage === null || OCTRegistrationImage === null)
    return <div>Error: No images found</div>;

  if (!FAFRegistrationImage.filepath || !OCTRegistrationImage.filepath)
    return <div>Error: No images found - missing filepath(s)</div>;

  const fixedLookup: ImageLookup = {
    filepath: OCTRegistrationImage.filepath,
    subImageKey: "RED",
    eye,
    type: "OCT",
  };
  const unfixedLookup: ImageLookup = {
    filepath: FAFRegistrationImage.filepath,
    subImageKey: "FAF",
    eye,
    type: "FAF",
  };
  // setFixedLookup(eye, fixedLookup);

  return (
    <>
      <ImageTransform
        fixed={fixedLookup}
        unfixed={unfixedLookup}
        onTransform={onTransform}
      />
      {/* <ImagePropertiesOCR
        eye={eye}
        capture_date={capture_date}
        types={["FAF"]}
      /> */}
    </>
  );
};

const RegistrationComponent = ({
  eye,
  capture_date,
  onFAFTransform,
  onFAFONHTransform,
}: {
  eye: "OD" | "OS";
  capture_date: string;
  onFAFTransform: (ImageRegistrationTransform) => void;
  onFAFONHTransform: (ImageRegistrationTransform) => void;
}) => {

  return (
    <>
      <FAFRegistrationComponent eye={eye} capture_date={capture_date} onTransform={onFAFTransform} />
      <FAFONHRegistrationComponent eye={eye} capture_date={capture_date} onTransform={onFAFONHTransform} />
    </>
  );
}
