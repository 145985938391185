import React, { useState, useRef, useEffect, useCallback } from 'react';
import { lerp1 } from '../../types/LayoutTypes';
import { Icon } from '@iconify/react/dist/iconify.js';
import { Slider } from '../ImageTransform';

interface ZoomableSVGCanvasProps {
  width: number;
  height: number;
  children: React.ReactNode;
  onTransform?: (transform: { x: number; y: number; scale: number }) => void;
}

const ZoomableSVGCanvas: React.FC<ZoomableSVGCanvasProps> = ({
  width,
  height,
  children,
  onTransform,
}) => {
  const [transform, setTransform] = useState({ x: 0, y: 0, scale: 1 });
  const [dragging, setDragging] = useState(false);
  const svgRef = useRef<SVGSVGElement>(null);
  const lastMousePositionRef = useRef({ x: 0, y: 0 });

  const minScale = 0.5;
  const maxScale = 10;

  const applyZoom = useCallback(
    (delta: number, clientX: number, clientY: number) => {
      setTransform((prev) => {
        const svgRect = svgRef.current?.getBoundingClientRect();
        if (!svgRect) return prev;

        const mouseX = clientX - svgRect.left;
        const mouseY = clientY - svgRect.top;

        const zoom = 1 + -delta * 0.001;
        const newScale = Math.min(maxScale, Math.max(minScale, prev.scale * zoom));

        const svgX = (mouseX - prev.x) / prev.scale;
        const svgY = (mouseY - prev.y) / prev.scale;

        const newX = mouseX - svgX * newScale;
        const newY = mouseY - svgY * newScale;

        return { x: newX, y: newY, scale: newScale };
      });
    },
    []
  );

  const handleWheel = useCallback(
    (event: WheelEvent) => {
      event.preventDefault();
      applyZoom(event.deltaY, event.clientX, event.clientY);
    },
    [applyZoom]
  );

  const handleMouseDown = useCallback((event: React.MouseEvent<SVGSVGElement>) => {
    setDragging(true);
    svgRef.current!.style.cursor = 'grabbing';
    lastMousePositionRef.current = { x: event.clientX, y: event.clientY };
  }, []);

  const handleMouseMove = useCallback(
    (event: React.MouseEvent<SVGSVGElement>) => {
      if (!dragging) return;
      const dx = event.clientX - lastMousePositionRef.current.x;
      const dy = event.clientY - lastMousePositionRef.current.y;
      const margin = transform.scale * width / 2;
      const edge = margin * 3;
      const q = edge - width;

      setTransform((prev) => ({
        x: Math.min(margin, Math.max(-q, prev.x + dx)),
        y: Math.min(margin, Math.max(-q, prev.y + dy)),
        scale: prev.scale,
      }));

      lastMousePositionRef.current = { x: event.clientX, y: event.clientY };
    },
    [dragging]
  );

  const handleMouseUp = useCallback(() => {
    setDragging(false);
    svgRef.current!.style.removeProperty('cursor');
  }, []);

  useEffect(() => {
    const svg = svgRef.current;
    if (!svg) return;

    svg.addEventListener('wheel', handleWheel, { passive: false });

    return () => {
      svg.removeEventListener('wheel', handleWheel);
    };
  }, [handleWheel]);

  useEffect(() => {
    if (onTransform) {
      onTransform(transform);
    }
  }, [transform, onTransform]);

  const ZoomControls = () => {
    return (
      <div className="flex gap-1 justify-end">
        <button
          onClick={() => {
            setTransform({ x: 0, y: 0, scale: 1 });
          }}
          className="p-1 border border-gray-300 rounded text-sm px-1.5 font-semibold"
        >
          Reset Zoom
        </button>

        <button
          onClick={() => {
            // set lastMousePosition to the center of the SVG
            const rect = svgRef.current!.getBoundingClientRect();
            const clientCoords = {
              x: rect.left + rect.width / 2,
              y: rect.top + rect.height / 2,
            };

            // const simulatedWheelEvent = new WheelEvent('wheel', { deltaY: 500 });
            applyZoom(500, clientCoords.x, clientCoords.y);
          }}
          className="p-1 border border-gray-300 rounded"
        >
          <Icon icon="heroicons-solid:zoom-out" className="w-5 h-5" />
        </button>
        <button
          onClick={() => {
            // set lastMousePosition to the center of the SVG
            const rect = svgRef.current!.getBoundingClientRect();
            const clientCoords = {
              x: rect.left + rect.width / 2,
              y: rect.top + rect.height / 2,
            };

            // const simulatedWheelEvent = new WheelEvent('wheel', { deltaY: -500 });
            applyZoom(-500, clientCoords.x, clientCoords.y);
          }}
          className="p-1 border border-gray-300 rounded"
        >
          <Icon icon="heroicons-solid:zoom-in" className="w-5 h-5" />
        </button>
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-1">
      <svg
        ref={svgRef}
        width={width}
        height={height}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        // style={{ cursor: dragging ? 'grabbing' : 'default', overflow: 'hidden' }}
        className="border select-none"
        style={{ overflow: 'hidden' }}
      >
        <g transform={`translate(${transform.x}, ${transform.y}) scale(${transform.scale})`}>
          {children}
        </g>
      </svg>
      <ZoomControls />
    </div>
  );
};

export default ZoomableSVGCanvas;