import React, {
  useState,
  useRef,
  useEffect,
} from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import useWizardStore from '../stores/useWizardStore';
// import { db, clinic_user_retinal_images } from '../../db';
// import { ImageRenderer } from './ImageRenderer';

export const StudyEyeSelector = ({
  onSelect,
}) : JSX.Element => {
  const studyEye = useWizardStore(state => state.studyEye);
  const setStudyEye = useWizardStore(state => state.setStudyEye);

  function handleSelect_Change(e: React.ChangeEvent<HTMLSelectElement>) {
    onSelect(e.target.value);
    if (e.target.value == 'OD' || e.target.value == 'OS')
      setStudyEye(e.target.value);
  }

  const selected = studyEye ?? '';

  return (
    <div className='flex gap-4'>
      <select value={selected} onChange={handleSelect_Change} className='bg-blue-100 p-2 rounded-md'>
        <option value='' className='text-gray-600'>Select...</option>
        <option value='OD'>OD</option>
        <option value='OS'>OS</option>
      </select>
    </div>
  );
};
