import React, {
  useState,
  useEffect,
  useRef,
  // useReducer,
  // useMemo,
  // useContext,
} from 'react';
import { unstable_batchedUpdates } from 'react-dom';

interface CroppedImageProps {
  x: number;
  y: number;
  w: number;
  h: number;
  url?: string;
  blob?: Blob;
}
export const CroppedImage = ({
  x,
  y,
  w,
  h,
  url,
  blob,
}: CroppedImageProps): JSX.Element => {

  const myURL = url ?? URL.createObjectURL(blob!);
  // base64 image
  // const [base64, setBase64] = useState<string | null>(null);

  // useEffect(() => {
  //   if (blob) {
  //     const reader = new FileReader();
  //     reader.onload = () => {
  //       unstable_batchedUpdates(() => {
  //         setBase64(reader.result as string);
  //       });
  //     };
  //     reader.readAsDataURL(blob);
  //   }
  // }, [blob]);

  // useEffect(() => {
  //   if (url) {
  //     // get the image from the url and convert it to base64
  //     const img = new Image();
  //     img.crossOrigin = 'Anonymous';
  //     img.src = url;
  //     img.onload = () => {
  //       const canvas = document.createElement('canvas');
  //       const ctx = canvas.getContext('2d');
  //       canvas.width = img.width;
  //       canvas.height = img.height;
  //       ctx?.drawImage(img, 0, 0);
  //       const dataURL = canvas.toDataURL('image/png');
  //       setBase64(dataURL);
  //     };
  //   }
  // }, [url]);



  return (
    <svg width={w ?? 9000} height={h ?? 9000}>
      {/* {base64 &&  */}
      <image
        href={myURL}
        crossOrigin='anonymous'
        x={-x}
        y={-y}
      />
      {/* } */}
    </svg>
  );
};
