class WebTools
{
  static async fetch(url: string, init?: RequestInit)
  {
    const response = await fetch(url, init);
    if (!response)
      throw new ResponseError('Fetched empty response!');
    if (!response.ok) {
      const text = await response.text();
      throw new HttpError(response.status, text);
    }
    return response;
  }

  static async apiPost<T>(url: string, body: any, abort?: AbortSignal)
  {
    const init = {
      method: 'POST',
      body: JSON.stringify(body),
    };
    if (abort)
      init['signal'] = abort;
    const response = await WebTools.fetch(url, init);
    return response.json() as T;
  }

  static async getFile(url: string, init?: RequestInit)
  {
    const response = await WebTools.fetch(url, init);
    return response.arrayBuffer();
  }
}

class ResponseError extends Error
{
  constructor(message: string)
  {
    super(message);
    this.name = 'ResponseError';
  }
}

class HttpError extends Error
{
  statusCode: number;
  constructor(status: number, message: string)
  {
    super(message);
    this.name = 'HttpError';
    this.statusCode = status;
  }
}

export {
  WebTools,
  ResponseError,
  HttpError,
};
