import * as common from '../../common';
import { db } from "@/db";
import {
  ImageRegistrationTransform,
  LocalizerData,
} from './ImagingTypes';
import useWizardStore from '../stores/useWizardStore';
import Papa from 'papaparse';

export interface LayoutDB {
  name: string;
  version: number;
  metadata: any;
  localizer_data: LocalizerData | undefined;
  csv: string;
  vvp_layout_id: number;
}

// export interface LayoutMeta {
// }

export interface LayoutRow {
  stimulus_group_id: number;
  stimulus_x_deg: number;
  stimulus_y_deg: number;
  stimulus_diameter_deg: number;
  stimulus_eye: string;
  stimulus_duration_sec: number;
  contrast_rule: string;
  stimulus_location_id: number;
  array_id: string;
  notes: string;
}

export interface ResultRow {
  locID: number;
  stim_eye: string;
  // stim_type: string;
  x_deg: number;
  y_deg: number;
  metric_name: string;
  metric: number;
}

export interface Coord {
  x: number;
  y: number;
}

export interface StimulusArray extends Coord {
  id: string;
  members: number[];
  coords: Coord[];
  stimDiameter: number;
  // color: string;
}

export interface Crop extends Coord {
  width: number;
  height: number;
}

export interface ImageConfig {
  RED: {
    blob: Blob;
    crop: Crop;
    fovea: Coord;
  };
  BSCAN: {
    blobs: Blob[];
    urls: string[];
    crop: Crop;
  }
  FAF: {
    blob: Blob;
    url: string;
    crop: Crop;
    reg: ImageRegistrationTransform;
  };
  FAFONH: {
    blob: Blob;
    url: string;
    crop: Crop;
    reg: ImageRegistrationTransform;
  };
  filenames: string[];
}

export function checkDistance(a: Coord, b: Coord, dist: number): boolean {
  const dx = a.x - b.x;
  const dy = a.y - b.y;
  return dx * dx + dy * dy <= dist * dist;
}

const E2 = Number.EPSILON * 2;

export function rotate(p: Coord, deg: number): Coord {
  const rad = Math.PI * deg / 180;
  let s = Math.sin(rad);
  let c = Math.cos(rad);
  if (Math.abs(s) < E2) s = 0;
  if (Math.abs(c) < E2) c = 0;
  return {
    x: p.x * c - p.y * s,
    y: p.x * s + p.y * c,
  };
}

export function turn(p: Coord, quarters: number) {
  const mod = (quarters % 4 + 4) % 4;
  switch (mod) {
  case 1:
    return { x: -p.y, y: p.x };
  case 2:
    return { x: -p.x, y: -p.y };
  case 3:
    return { x: p.y, y: -p.x };
  }
  return { ...p };
}

export function lerp1(value: number, start: number, end: number): number {
  return start + value * (end - start);
}

export function lerp(value: number, fromStart: number, fromEnd: number, toStart: number, toEnd: number): number {
  return toStart + (value - fromStart) * (toEnd - toStart) / (fromEnd - fromStart);
}

export function lerpColor(value: number, fromColor: number[], toColor: number[]): number[] {
  return [
    Math.round(lerp1(value, fromColor[0], toColor[0])),
    Math.round(lerp1(value, fromColor[1], toColor[1])),
    Math.round(lerp1(value, fromColor[2], toColor[2])),
  ];
}

export function hexToRGB(hex: string): number[] {
  return [
    parseInt(hex.substring(1, 3), 16),
    parseInt(hex.substring(3, 5), 16),
    parseInt(hex.substring(5, 7), 16),
  ];
}

export function rgbToHex(rgb: number[]): string {
  return `#${rgb[0].toString(16)}${rgb[1].toString(16)}${rgb[2].toString(16)}`;
}

export function getBounds(coords: Coord[]) {
  const min = { ...coords[0] };
  const max = { ...coords[0] };
  for (const c of coords) {
    min.x = Math.min(min.x, c.x);
    min.y = Math.min(min.y, c.y);
    max.x = Math.max(max.x, c.x);
    max.y = Math.max(max.y, c.y);
  }
  return {
    minX: min.x,
    minY: min.y,
    maxX: max.x,
    maxY: max.y,
  };
}

export function SVGtoDOMMatrix(mx: SVGMatrix): DOMMatrix {
  return new DOMMatrix([mx.a, mx.b, mx.c, mx.d, mx.e, mx.f]);
}

export function childToParentMatrix(c: SVGGraphicsElement, p: SVGGraphicsElement): DOMMatrix {
  return SVGtoDOMMatrix(p.getCTM()!.inverse().multiply(c.getCTM()!));
}

export function getFirstID(locIDs: number[]) {
  return locIDs.reduce((a,i) => i < 0 ? Math.max(a,i) : Math.min(a,i));
}

export function getArrayStats(coords: Coord[]) {
  const bounds = getBounds(coords);
  const center = { x: (bounds.minX + bounds.maxX) / 2, y: (bounds.minY + bounds.maxY) / 2 };
  const w = bounds.maxX - bounds.minX;
  const h = bounds.maxY - bounds.minY;
  const interval = Math.max(w,h) / (coords.length-1);
  return {
    bounds,
    center,
    w,
    h,
    interval,
  };
}

export function getDirection(letter: string, eye: 'OD' | 'OS'): Coord {
  const vec = { x: 0, y: 0 };
  switch (letter) {
  case 'T':
    vec.x = eye == 'OS' ? 1 : -1;
    break;
  case 'N':
    vec.x = eye == 'OD' ? 1 : -1;
    break;
  case 'S':
    vec.y = -1;
    break;
  case 'I':
    vec.y = 1;
    break;
  }
  return vec;
}

export function generateSpindle(vec: Coord, center: Coord, interval: number): Coord[] {
  const inner = { ...center };
  inner.x -= 1.5 * interval * vec.x;
  inner.y -= 1.5 * interval * vec.y;
  const spindle: Coord[] = [];
  for (let i = 0; i < 7; i++) {
    spindle.push({
      x: inner.x + i * interval * vec.x,
      y: inner.y + i * interval * vec.y,
    });
  }
  const clockwise = turn(vec, 1);
  spindle.push({
    x: spindle[3].x - interval * clockwise.x,
    y: spindle[3].y - interval * clockwise.y,
  });
  spindle.push({
    x: spindle[3].x + interval * clockwise.x,
    y: spindle[3].y + interval * clockwise.y,
  });
  return spindle;
}

export function getArrayColor(id: string) {
  if (id.startsWith('BS')) return '#07b';
  if (id.startsWith('GA')) return '#738';
  if (id.startsWith('HP')) return '#fa0';
  if (id.startsWith('PG')) return '#399';
  if (id == 'T') return '#738';
  return '#c22';
}

export function getStrokeColor(id: string) {
  if (id.startsWith('BS')) return '#6cf';
  if (id.startsWith('GA')) return '#f9f';
  if (id.startsWith('HP')) return '#fc6';
  if (id.startsWith('PG')) return '#7dd';
  if (id == 'T') return '#f9f';
  return '#e88';
}

const papaOptions = {
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true,
};

export function parseLayout(csv: string): LayoutRow[] {
  return Papa.parse(csv, papaOptions).data as LayoutRow[];
}

export function parseVVPResult(csv: string): ResultRow[] {
  return Papa.parse(csv, papaOptions).data as ResultRow[];
}

export function flipLayout(rows: LayoutRow[]): LayoutRow[] {
  return rows.map(r => ({
    ...r,
    stimulus_eye: r.stimulus_eye == 'right' ? 'left' : 'right',
    stimulus_x_deg: -r.stimulus_x_deg,
    stimulus_location_id: -r.stimulus_location_id,
  }));
}



export function applyEdits(eye: string, edits: EditAction[], template: LayoutRow[]): LocUpdate[] {
  const lr = eye == 'OD' ? 'right' : 'left';
  const results: LocUpdate[] = [];
  for (const e of edits) {
    const split = e.id.split(' ');
    if (split.length > 1) {
      const lid = parseInt(split[1]);
      const row = template.find(r => r.stimulus_location_id == lid)!;
      const p = new DOMPointReadOnly(row.stimulus_x_deg, row.stimulus_y_deg);
      const tp = e.action.matrix.transformPoint(p);
      results.push({ id: lid, arrayID: e.arrayType, x: tp.x, y: tp.y });
    }
    else {
      const rows = template.filter(r => r.stimulus_eye == lr && r.array_id == e.arrayType);
      for (const r of rows.sort((a,b) => a.stimulus_location_id - b.stimulus_location_id)) {
        const p = new DOMPointReadOnly(r.stimulus_x_deg, r.stimulus_y_deg);
        const tp = e.action.matrix.transformPoint(p);
        results.push({ id: r.stimulus_location_id, arrayID: e.arrayType, x: tp.x, y: tp.y });
      }
    }
  }
  return results;
}

export function applyPRL(eye: string, matrix: DOMMatrixReadOnly, template: LayoutRow[]): LocUpdate[] {
  const lr = eye == 'OD' ? 'right' : 'left';
  const results: LocUpdate[] = [];
  for (const r of template.filter(r => r.stimulus_eye == lr)) {
    const p = new DOMPointReadOnly(r.stimulus_x_deg, r.stimulus_y_deg);
    const tp = matrix.transformPoint(p);
    results.push({ id: r.stimulus_location_id, arrayID: r.array_id, x: tp.x, y: tp.y });
  }
  return results;
}

export function getVascularIDs(rows: LayoutRow[], marks: Coord[]) {
  const ids: number[] = [];
  const dice: number[] = [];
  for (const r of rows) {
    const c = { x: r.stimulus_x_deg, y: r.stimulus_y_deg };
    for (let i = 0; i < marks.length; i++) {
      if (checkDistance(c, marks[i], r.stimulus_diameter_deg/3)) {
        ids.push(r.stimulus_location_id);
        dice.push(i);
        break;
      }
    }
  }
  const rem: Coord[] = [];
  let j = dice.pop();
  for (let i = marks.length-1; i >= 0; i--) {
    if (i === j)
      j = dice.pop();
    else rem.push(marks[i]);
  }
  return {
    ids,
    remains: rem.reverse(),
  };
}

export function prepareCSV(template: LayoutRow[], updates: LocUpdate[]): LayoutRow[] {
  const output = [];
  const updateByID = new Map<number, LocUpdate>();
  for (const u of updates)
    updateByID.set(u.id, u);
  for (const row of template) {
    const orow = { ...row };
    output.push(orow);
    const u = updateByID.get(row.stimulus_location_id);
    if (!u) continue;
    orow.stimulus_x_deg = u.x;
    orow.stimulus_y_deg = u.y;
  }
  return output;
}

export function prepareHomeCSV(template: LayoutRow[], updates: LocUpdate[]): LayoutRow[] {
  const output = [];
  const rowsByGroup = common.buildMapGrouped<string, LayoutRow>(template, r => r.array_id);
  const updateByGroup = common.buildMapGrouped<string, LocUpdate>(updates, u => u.arrayID);
  for (const [k,v] of updateByGroup) {
    const hrows = rowsByGroup.get(k);
    if (!hrows)
      throw new Error(`Group ${k} not found in template`);
    for (const u of v) {
      output.push({
        ...hrows[0],
        stimulus_x_deg: u.x,
        stimulus_y_deg: u.y,
        stimulus_location_id: u.id,
        array_id: u.arrayID,
      });
    }
  }
  const updateGroups = new Set(updateByGroup.keys());
  const templateGroupsOnly = [...rowsByGroup.keys()].filter(g => !updateGroups.has(g));
  for (const g of templateGroupsOnly) {
    for (const row of rowsByGroup.get(g)!)
      output.push({ ...row });
  }
  return output;
}

export function serializeCSV(rows: LayoutRow[]): string {
  return Papa.unparse(rows.map(rowFixedPoint));
}

interface LayoutRowFixedPoint extends Omit<LayoutRow, 'stimulus_x_deg' | 'stimulus_y_deg'> {
  stimulus_x_deg: string;
  stimulus_y_deg: string;
}
function rowFixedPoint(row: LayoutRow): LayoutRowFixedPoint {
  return {
    ...row,
    stimulus_x_deg: trimTrailingZeroes(roundCompact(row.stimulus_x_deg).toFixed(4)),
    stimulus_y_deg: trimTrailingZeroes(roundCompact(row.stimulus_y_deg).toFixed(4)),
  };
}
function roundCompact(n: number): number {
  // to 4 decimal places
  const ni = Math.round(n * 10000);
  // slightly coarser if near *.**00 or *.**50
  if (ni % 50 < 3 || ni % 50 > 47) return Math.round(n * 1000) / 1000;
  return ni / 10000;
}
function trimTrailingZeroes(s: string): string {
  if (!s.includes('.'))
    return s;
  return s.replace(/(?<=[1-9])0+$/, '').replace(/\.0+$/, '');
}

export interface EditAction {
  id: string;
  arrayType: string;
  action: TransformAction;
}

export interface TransformAction {
  translation?: Coord;
  rotation?: number;
  matrix: DOMMatrixReadOnly;
}

export interface LocUpdate extends Coord {
  id: number;
  arrayID: string;
  isMarked?: boolean;
}

export async function loadImageConfig(eye: 'OD' | 'OS'): Promise<ImageConfig> {
  const {
    selectedCaptureDate,
    registrations,
    fovealPit,
  } = useWizardStore.getState();

  const fovCoord = { x: fovealPit[eye]!.enfaceX, y: fovealPit[eye]!.enfaceY };
  const metas = await db.metaWhereUser().filter(row => row.capture_date == selectedCaptureDate && row.eye == eye).sortBy('filepath');
  const metaFAF = metas.find(row => row.type == 'FAF');
  const metaFAFONH = metas.find(row => row.type == 'FAFONH');
  const metaOCTs = metas.filter(row => row.type == 'OCT');
  const metaProps = metas.filter(row => row.type.includes('Properties'));
  const octFetches = metaOCTs.map(row => db.getPng(row.filepath));
  const [pngFAF, pngFAFONH] = await Promise.all([
    // db.getPng(fixedPath),
    db.getPng(metaFAF!.filepath),
    db.getPng(metaFAFONH!.filepath),
  ]);
  const pngOCTs = await Promise.all(octFetches);
  const octBlobs = pngOCTs.map(b => new Blob([b], { type: 'image/png' }));
  const fafBlob = new Blob([pngFAF], { type: 'image/png' });
  const fafonhBlob = new Blob([pngFAFONH], { type: 'image/png' });
  const octLastFile = metaOCTs[metaOCTs.length-1].filepath;
  return {
    RED: {
      blob: octBlobs[0],
      crop: metaOCTs![0].sub_images['RED'],
      fovea: fovCoord,
    },
    BSCAN: {
      blobs: octBlobs,
      urls: octBlobs.map(b => URL.createObjectURL(b)),
      crop: metaOCTs![0].sub_images['BSCAN'],
    },
    FAF: {
      blob: fafBlob,
      url: URL.createObjectURL(fafBlob),
      crop: metaFAF!.sub_images['FAF'],
      reg: registrations[eye]!.FAF,
    },
    FAFONH: {
      blob: fafonhBlob,
      url: URL.createObjectURL(fafonhBlob),
      crop: metaFAFONH!.sub_images['FAF'],
      reg: registrations[eye]!.FAFONH,
    },
    filenames: [
      metaFAF!.filepath,
      metaFAFONH!.filepath,
      octLastFile,
      ...metaProps.map(row => row.filepath),
    ],
  };
}


const heatmap = [
  [90, 0, 90],
  [126, 21, 78],
  [198 , 63, 54],
  [250, 100, 0],
  [255, 148, 0],
  [245, 200, 0],
  [160, 255, 0],
];

const tdbColors = [
  '#000000',
  '#5a005a',
  '#600458',
  '#660756',
  '#720e52',
  '#7e154e',
  '#8a1c4a',
  '#962346',
  '#a22a42',
  '#ae313e',
  '#ba383a',
  '#c63f36',
  '#d24632',
  '#da4c28',
  '#e2521e',
  '#ea5814',
  '#f25e0a',
  '#fa6400',
  '#fb6c00',
  '#fc7400',
  '#fd7c00',
  '#fe8400',
  '#ff8c00',
  '#ff9400',
  '#ff9c00',
  '#ffa400',
  '#ffac00',
  '#ffb400',
  '#fabe00',
  '#f5c800',
  '#f0d200',
  '#ebdc00',
  '#e6e600',
  '#d8eb00',
  '#caf000',
  '#bcf500',
  '#aefa00',
  '#a0ff00',
];

export function getTDBColor(value: number): string {
  if (value <= 0) return tdbColors[0];
  if (value > 36) return tdbColors[37];
  return tdbColors[Math.floor(value)];
}
