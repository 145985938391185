
export type ImageRegistrationTransform = {
  translation: { x: number; y: number };
  rotation: number;
  scale: number;
};

export type ImageLookup = {
  filepath: string;
  eye: string;
  type: string;
  subImageKey?: string;
};

export type ImageRegistration = {
  FAF: ImageRegistrationTransform;// | null;
  FAFONH: ImageRegistrationTransform;// | null;
  FixedImage: ImageLookup;// | null;
};

export interface BScanData {
  scanIndex: number;
  scanX: number;
  enfaceX: number;
  enfaceY: number;
}

export interface LocalizerData {
  version: number;
  timestamp: number;
  captureDate: string;
  studyEye: 'OD' | 'OS' | null;
  registrations: {
    OD: ImageRegistration | null;
    OS: ImageRegistration | null;
  };
  fovealPit: {
    OD: BScanData | null;
    OS: BScanData | null;
  };
  filenames: string[];
}

export function upgradeLocalizerData(data): LocalizerData {
  if ('version' in data === false || data.version < 2) {
    let reg = data.registrations;
    return {
      ...data,
      version: 2,
      captureDate: data.captureDate.slice(0, 10),
      registrations: !reg ? null : {
        OD: !reg.FAF.OD ? null : {
          FAF: reg.FAF.OD,
          FAFONH: reg.FAFONH.OD,
          FixedImage: reg.FixedImage.OD,
        },
        OS: !reg.FAF.OS ? null : {
          FAF: reg.FAF.OS,
          FAFONH: reg.FAFONH.OS,
          FixedImage: reg.FixedImage.OS,
        },
      },
    };
  }
  return data;
}

export {}
