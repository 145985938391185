import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
} from 'react';
import useWizardStore from '../../stores/useWizardStore';
import {
  Coord,
  LayoutRow,
  getArrayColor,
  getStrokeColor,
} from '../../types/LayoutTypes';
import {
  LocData,
  makeRegTransform,
  renderCropped,
  renderFoveal,
  renderLoc,
  renderMark,
} from './elements';
import { renderPolarGrid } from './VisualFieldCanvas';


interface ReviewLayoutProps {
  csv: LayoutRow[],
  marked?: number[],
  prl?: { OD: Coord, OS: Coord },
  cyclo?: { OD: number, OS: number },
}
export const ReviewLayout = ({
  csv,
  marked,
  prl,
  cyclo,
}: ReviewLayoutProps): JSX.Element => {
  const studyEye = useWizardStore(state => state.studyEye);
  const imageConfig = useWizardStore(state => state.imageConfig);

  const locs = useMemo(() => csv.map(row => ({
    x: row.stimulus_x_deg,
    y: row.stimulus_y_deg,
    id: row.stimulus_location_id,
    arrayID: row.array_id,
    diameter: row.stimulus_diameter_deg,
    isMarked: !marked ? false : marked.includes(row.stimulus_location_id),
    fill: getArrayColor(row.array_id),
    stroke: getStrokeColor(row.array_id),
  }) as LocData), [csv, marked]);

  return (
    <div className='flex gap-2'>
      <div>
        {renderLayout('OD', locs.filter(l => l.id > 0), imageConfig.OD, prl?.OD, cyclo?.OD)}
        <div className='text-sm'>{`OD (${studyEye == 'OD' ? '' : 'non-'}study eye)`}</div>
      </div>
      <div>
        {renderLayout('OS', locs.filter(l => l.id < 0), imageConfig.OS, prl?.OS, cyclo?.OS)}
        <div className='text-sm'>{`OS (${studyEye == 'OS' ? '' : 'non-'}study eye)`}</div>
      </div>
    </div>
  );
};


const strokeWidthDeg = 0.05;

function renderLayout(eye: string, locs: LocData[], imageCfg, prl?: Coord, cyclo?: number) {
  const overlayScale = 30/imageCfg.RED.crop.width;
  const fovDeg: Coord = {
    x: overlayScale * imageCfg.RED.fovea.x,
    y: overlayScale * imageCfg.RED.fovea.y,
  };
  let fixationTransform = '';
  if (cyclo) fixationTransform = `rotate(${cyclo})`;
  if (prl) fixationTransform = `translate(${prl.x}, ${prl.y}) ` + fixationTransform;

  return (
    <svg key={`vvpgrid-${eye}`} width={480} height={360}>
      <rect x="-100%" y="-100%" width="400%" height="400%" fill="#404040" />
      <g transform={`translate(${240 + (eye == 'OD' ? -32 : 32)}, ${180})`}>
        <g transform='scale(11)' style={{ pointerEvents: 'none' }}>
          <g key='imaging' transform={fixationTransform}>
            <g transform={`translate(${-fovDeg.x}, ${-fovDeg.y})`}>
              <g transform={`scale(${overlayScale})`}>
                {makeRegTransform(imageCfg.FAFONH.reg, renderCropped(imageCfg.FAFONH.url, imageCfg.FAFONH.crop))}
                {makeRegTransform(imageCfg.FAF.reg, renderCropped(imageCfg.FAF.url, imageCfg.FAF.crop))}
              </g>
              {renderFoveal(fovDeg)}
            </g>
          </g>
          <g key='loc-data' strokeWidth={strokeWidthDeg}>
            {locs.map(renderLoc)}
            <g fill='none' stroke='white'>
              {locs.filter(l => l.isMarked).map(renderMark)}
            </g>
          </g>
        </g>
        <g opacity={0.5} fill="none" stroke='white' strokeWidth={10*strokeWidthDeg} style={{ pointerEvents: "none" }}>
          {renderPolarGrid(11)}
        </g>
      </g>
    </svg>
  );
}