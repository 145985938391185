import React, { useState, useRef, useEffect } from 'react';

type TooltipPosition = 'top' | 'right' | 'bottom' | 'left';

type TooltipProps = {
  content: React.ReactNode;
  children: React.ReactNode;
  position?: TooltipPosition;
  delay?: number;
  className?: string;
  tooltipClassName?: string;
};

const Tooltip = ({
  content,
  children,
  position = 'top',
  delay = 200,
  className = '',
  tooltipClassName = '',
}: TooltipProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const [coords, setCoords] = useState({ x: 0, y: 0 });
  const tooltipRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const timerRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  const updatePosition = () => {
    if (!containerRef.current || !tooltipRef.current) return;

    const triggerRect = containerRef.current.getBoundingClientRect();
    const tooltipRect = tooltipRef.current.getBoundingClientRect();
    const scrollX = window.scrollX;
    const scrollY = window.scrollY;

    let x = 0;
    let y = 0;

    switch (position) {
      case 'top':
        x = triggerRect.left + (triggerRect.width - tooltipRect.width) / 2;
        y = triggerRect.top - tooltipRect.height - 8;
        break;
      case 'bottom':
        x = triggerRect.left + (triggerRect.width - tooltipRect.width) / 2;
        y = triggerRect.bottom + 8;
        break;
      case 'left':
        x = triggerRect.left - tooltipRect.width - 8;
        y = triggerRect.top + (triggerRect.height - tooltipRect.height) / 2;
        break;
      case 'right':
        x = triggerRect.right + 8;
        y = triggerRect.top + (triggerRect.height - tooltipRect.height) / 2;
        break;
    }

    // Keep tooltip within viewport bounds
    x = Math.max(8, Math.min(x, window.innerWidth - tooltipRect.width - 8));
    y = Math.max(8, Math.min(y, window.innerHeight - tooltipRect.height - 8));

    setCoords({ x, y });
  };

  const showTooltip = () => {
    timerRef.current = setTimeout(() => {
      setIsVisible(true);
      requestAnimationFrame(updatePosition);
    }, delay);
  };

  const hideTooltip = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    setIsVisible(false);
  };

  // Update position if content changes
  useEffect(() => {
    if (isVisible) {
      updatePosition();
    }
  }, [content, isVisible]);

  useEffect(() => {
    window.addEventListener('resize', updatePosition);
    window.addEventListener('scroll', hideTooltip);
    window.addEventListener('click', hideTooltip);
    window.addEventListener('mouseleave', hideTooltip);

    return () => {
      window.removeEventListener('resize', updatePosition);
      window.removeEventListener('scroll', hideTooltip);
      window.removeEventListener('click', hideTooltip);
      window.removeEventListener('mouseleave', hideTooltip);
    };
  }, []);

  return (
    <>
      <div
        ref={containerRef}
        className={`inline-block ${className}`}
        onMouseEnter={showTooltip}
        onMouseLeave={hideTooltip}
        onTouchStart={showTooltip}
        onTouchEnd={hideTooltip}
        aria-describedby="tooltip"
      >
        {children}
      </div>
      {/* {isVisible && ( */}
      <div
        ref={tooltipRef}
        role="tooltip"
        id="tooltip"
        className={`fixed z-50 px-2 py-1 text-xs font-normal text-white bg-gray-800 rounded shadow-lg pointer-events-none
            transition-opacity duration-100 ease-in
            ${position === 'top' ? '-translate-y-1' : ''}
            ${position === 'bottom' ? 'translate-y-1' : ''}
            ${position === 'left' ? '-translate-x-1' : ''}
            ${position === 'right' ? 'translate-x-1' : ''}
            ${isVisible ? 'opacity-100' : 'opacity-0'}
            ${tooltipClassName}
            `}
        style={{
          left: `${coords.x}px`,
          top: `${coords.y}px`
        }}
      >
        {content}
      </div>
      {/* )} */}
    </>
  );
};

export default Tooltip;