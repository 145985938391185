import React from "react";
import "./App.css";
import { UploadDropArea } from "./rewrite/components/UploadDropArea";
import { ImageListTable } from "./rewrite/components/ImageListTable";
import ProcessImages from "./rewrite/components/ProcessImages";
import { PreviewProcessedImageFiles } from "./rewrite/components/PreviewProcessedImageFiles";
import { OCRTest } from "./rewrite/components/OCRTest";
import {
  createBrowserRouter,
  RouterProvider,
  useSearchParams,
} from "react-router-dom";
import { Wizard } from "./rewrite/routes/Wizard";
import { UploadImagesWithPreview } from "./rewrite/components/UploadImagesWithPreview";
import { ImagePropertiesOCR } from "./rewrite/components/ImagePropertiesOCR";
import { ImageTransform } from "./rewrite/components/ImageTransform";
import { Tabs, Tab } from "./rewrite/components/Tabs";
import { MainContext } from "./rewrite/contexts/MainContext";
import { ImageSyncService } from "./rewrite/contexts/ImageSyncService";
import { TestImageSync } from "./rewrite/components/TestImageSync";
import { db } from "./db";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <Wizard isHome={false} />
      </>
    ),
  },
  {
    path: "/upload-images",
    element: <UploadImagesWithPreview onDropReady={(b) => {}} />,
  },
  {
    path: "/image-list-table",
    element: <ImageListTable />,
  },
  {
    path: "/preview-processed-images",
    element: <PreviewProcessedImageFiles />,
  },
  {
    path: "/image-server-sync",
    element: <TestImageSync />,
  },
  {
    path: "/ocr-test",
    element: <OCRTest />,
  },
  {
    path: "/images-ocr",
    element: <ImagePropertiesOCR capture_date="" eye="OD" types={[]} />,
  },
  {
    path: "/image-register",
    element: (
      <ImageTransform
        fixed={{
          filepath: "2321_015_OCT_OD_000",
          subImageKey: "RED",
          eye: "OD",
          type: "OCT",
        }}
        unfixed={{
          filepath: "2321_015_FAF_OD_20240215",
          subImageKey: "FAF",
          eye: "OD",
          type: "FAF",
        }}
      />
    ),
  },
  {
    path: "/tabs",
    element: (
      <Tabs>
        <Tab label="OCT">test2</Tab>
        <Tab label="FAF-2">test</Tab>
        <Tab label="FAF-1M">test3</Tab>
      </Tabs>
    ),
  },
  {
    path: "/training-localizer",
    element: <Wizard isHome={false} />,
  },
  {
    path: "/home-bundle",
    element: <Wizard isHome={true} />,
  },
]);

const url = new URL(window.location.href);
const auth = {
  u: url.searchParams.get("u") || process.env.REACT_APP_VIVID_UN || "",
  p: url.searchParams.get("p") || process.env.REACT_APP_VIVID_HASH || "",
  patient_id: parseInt(
    url.searchParams.get("selectedPatientId") ||
      process.env.REACT_APP_VIVID_PT_ID ||
      "0"
  ),
};

const mainCtx = {
  imageSyncService: new ImageSyncService(),
  isHome: !!url.searchParams.get("home"),
  u: auth.u,
  p: auth.p,
  patient_id: auth.patient_id,
  crc_name:
    url.searchParams.get("crc_name") ||
    process.env.REACT_APP_VIVID_CRC_NAME ||
    "",
  session_uuid: crypto.randomUUID(),
};
mainCtx.imageSyncService.cachedAuth = auth;
db.cachedUser = auth.patient_id;
console.log("app init user ", db.cachedUser);

function App() {
  return (
    <div className="p-[12px] overflow-auto">
      <MainContext.Provider value={mainCtx}>
        <RouterProvider router={router} />
      </MainContext.Provider>
    </div>
  );
}

export default App;
