export function transferError(e: Error) {
  let n = e.constructor.name;
  if (n == 'DOMException')
    n = e.name;
  return {
    name: n,
    message: e.message,
    // stack: e.stack,
    ...e,
  };
}

export function wait(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function generalDateTime(d: Date = new Date()) {
  return d.toISOString().slice(0, 19).replace('T', ' ');
}

export function dateStrUTC(d: Date = new Date()) {
  return d.toISOString().slice(0, 10);
}

export function dateStrLocal(d: Date = new Date()) {
  return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-${String(d.getDate()).padStart(2, '0')}`;
}

export function buildMap<TKey, TValue>(arr: TValue[], keySelector: (v: TValue) => TKey): Map<TKey, TValue> {
  const map = new Map<TKey, TValue>();
  for (const v of arr) {
    const k = keySelector(v);
    map.set(k, v);
  }
  return map;
}

export function buildMapGrouped<TKey, TValue>(arr: TValue[], keySelector: (v: TValue) => TKey): Map<TKey, TValue[]> {
  const map = new Map<TKey, TValue[]>();
  for (const v of arr) {
    const k = keySelector(v);
    if (!map.has(k))
      map.set(k, []);
    map.get(k)!.push(v);
  }
  return map;
}
