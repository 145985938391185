import React, { useEffect, useRef, useState, useContext, useCallback, createContext, forwardRef } from "react";
import {
  Stage,
  Layer,
  Image as KonvaImage,
  Rect,
  Line,
  Circle as KonvaCircle,
  Group,
  KonvaRenderer,
} from "react-konva";
import { KonvaEventObject } from "konva/lib/Node";
import Konva from "konva";
import { Matrix, solve } from "ml-matrix";
import { Vector2d } from "konva/lib/types";

import { unstable_batchedUpdates } from "react-dom";
import { db, getSubImage } from "../../db";
import useWizardStore from "../stores/useWizardStore";
import { MainContext } from "../contexts/MainContext";
import {
  ImageLookup,
  ImageRegistrationTransform,
} from "../types/ImagingTypes";
import { Icon } from "@iconify/react/dist/iconify.js";
import Tooltip from "./Tooltip";

// Interfaces
interface ImageTransformProps {
  fixed: ImageLookup;
  unfixed: ImageLookup;
  onTransform?: (transform: ImageRegistrationTransform) => void;
}

interface ZoomedImageProps {
  image: ImageBitmap;
  scale: number;
  idx: number;
  arr: number[][];
  setArr: React.Dispatch<React.SetStateAction<number[][]>>;
  circles: { x: number; y: number }[];
  setCircles: React.Dispatch<React.SetStateAction<{ x: number; y: number }[]>>;
}

interface Transform {
  trans_x: number;
  trans_y: number;
  scale: number;
  rotation: number;
}

// Constants
const IMAGE_SIZE = 400;
const ZOOMED_IMAGE_SIZE = 100;

const IndexToColor = (i: number) => {
  // red, green, blue, yellow
  const colors = ["#ff0000", "#00ff00", "#0000ff", "#ffff00"];

  return colors[i % colors.length];
};

// Helper Components
const ZoomedImage = forwardRef<Konva.Image, ZoomedImageProps>(({
  scale,
  image,
  idx,
  arr,
  setArr,
  circles,
  setCircles,
}, ref) => {
  if (!image || !arr[idx]) {
    return (
      <div style={{ width: ZOOMED_IMAGE_SIZE, height: ZOOMED_IMAGE_SIZE }} />
    );
  }

  const editPoint = (e: KonvaEventObject<MouseEvent>) => {
    const stage = e.target.getStage();
    const location = stage?.getPointerPosition();
    if (location) {
      const scale: Vector2d = stage!.getLayers()[0].getChildren()[0].scale()!;
      arr[idx] = [
        (arr[idx][0] * scale.x + location.x - ZOOMED_IMAGE_SIZE / 2) / scale.x,
        (arr[idx][1] * scale.y + location.y - ZOOMED_IMAGE_SIZE / 2) / scale.y,
      ];
      setArr([...arr]);

      if (circles.length > 0) {
        const newCircles = [...circles];
        const circle = newCircles[idx];
        newCircles[idx] = {
          x:
            (circle.x * scale.x + location.x - ZOOMED_IMAGE_SIZE / 2) / scale.x,
          y:
            (circle.y * scale.y + location.y - ZOOMED_IMAGE_SIZE / 2) / scale.y,
        };
        setCircles(newCircles);
      } else {
        setCircles([
          ...circles,
          { x: location.x / scale.x, y: location.y / scale.y },
        ]);
      }
    }
  };

  return (
    <div className="relative">
      {/* Delete Icon */}
      <div className="absolute top-0 right-0 z-10">
        <button
          className="border-l border-b border-white text-white bg-black rounded-sm flex items-center justify-center"
          onClick={() => {
            setArr(arr.filter((_, i) => i !== idx));
            setCircles(circles.filter((_, i) => i !== idx));
          }}
        >
          <Icon icon="feather:x" className="w-3 h-3 font-bold stroke-white stroke-3" />
        </button>
      </div>
      <Stage width={ZOOMED_IMAGE_SIZE} height={ZOOMED_IMAGE_SIZE}>
        <Layer onMouseDown={editPoint} onDragMove={editPoint}>
          <KonvaImage
            scaleX={(IMAGE_SIZE / image.width) * scale}
            scaleY={(IMAGE_SIZE / image.height) * scale}
            x={
              -arr[idx][0] * ((IMAGE_SIZE / image.width) * scale) +
              ZOOMED_IMAGE_SIZE / 2
            }
            y={
              -arr[idx][1] * ((IMAGE_SIZE / image.width) * scale) +
              ZOOMED_IMAGE_SIZE / 2
            }
            ref={ref}
            image={image}
          />
          <Line
            points={[
              ZOOMED_IMAGE_SIZE / 4,
              ZOOMED_IMAGE_SIZE / 2,
              (ZOOMED_IMAGE_SIZE / 4) * 3,
              ZOOMED_IMAGE_SIZE / 2,
            ]}
            stroke={IndexToColor(idx) + 66}
            strokeWidth={1}
          />
          <Line
            points={[
              ZOOMED_IMAGE_SIZE / 2,
              ZOOMED_IMAGE_SIZE / 4,
              ZOOMED_IMAGE_SIZE / 2,
              (ZOOMED_IMAGE_SIZE / 4) * 3,
            ]}
            stroke={IndexToColor(idx) + 66}
            strokeWidth={1}
          />
          <KonvaCircle
            x={ZOOMED_IMAGE_SIZE / 2}
            y={ZOOMED_IMAGE_SIZE / 2}
            radius={2}
            fill={IndexToColor(idx)}
            stroke="black"
            strokeWidth={0}
          />
          <KonvaCircle
            x={ZOOMED_IMAGE_SIZE / 2}
            y={ZOOMED_IMAGE_SIZE / 2}
            radius={ZOOMED_IMAGE_SIZE / 4}
            fill="transparent"
            stroke={IndexToColor(idx)}
            strokeWidth={2}
          />
        </Layer>
      </Stage>
    </div>
  );
});

// Main Component
export const ImageTransform: React.FC<ImageTransformProps> = ({
  fixed,
  unfixed,
  onTransform,
}) => {
  const { u, p, patient_id } = useContext(MainContext)!;
  const setFixedLookup = useWizardStore(state => state.setFixedLookup);
  const [fixedImage, setFixedImage] = useState<ImageBitmap | null>(null);
  const [unfixedImage, setUnfixedImage] = useState<ImageBitmap | null>(null);

  // const fixedImageRef = useRef<Konva.Image>(null);
  // const unfixedImageRef = useRef<Konva.Image>(null);
  const [opacity, setOpacity] = useState<number>(0.5);



  const [transform, setTransform] = useState<ImageRegistrationTransform>({
    translation: { x: 0, y: 0 },
    scale: 1,
    rotation: 0,
  });
  const [ptsSrc, setPtsSrc] = useState<number[][]>([]);
  const [ptsDst, setPtsDst] = useState<number[][]>([]);

  const fixedStageRef = useRef<Konva.Stage>(null);
  const unfixedStageRef = useRef<Konva.Stage>(null);


  const {
    contrast: contrastFixed,
    setContrast: setContrastFixed,
    imageBitmap: fixedImageContrast,
    imageRef: fixedImageRef,
    setCancelContrast: setCancelContrastFixed,
  } = useContrastImage({ image: fixedImage });

  const {
    contrast: contrastUnfixed,
    setContrast: setContrastUnfixed,
    imageBitmap: unfixedImageContrast,
    imageRef: unfixedImageRef,
    setCancelContrast: setCancelContrastUnfixed,
  } = useContrastImage({ image: unfixedImage });

  const Contrast = {
    min: 100,
    max: 500,
    step: 1,
    valueRenderer: (value: number) => (
      <div className="w-full flex justify-between text-xs pt-0">
        <div>Contrast: {(value / 100).toFixed(2)}</div>
      </div>
    ),
  }

  // mount
  useEffect(() => {
    initAsync();
  }, []);

  useEffect(() => {
    onTransform?.(transform);
  }, [transform]);

  async function initAsync() {
    let fixedBitmap: ImageBitmap;
    let unfixedBitmap: ImageBitmap;
    try {
      const all = await Promise.all([
        getSubImage(fixed.filepath, fixed.subImageKey),
        getSubImage(unfixed.filepath, unfixed.subImageKey),
      ]);
      fixedBitmap = all[0];
      unfixedBitmap = all[1];
    } catch (error) {
      console.error("Error loading images:", error);
      return;
    }
    const reg = useWizardStore.getState().registrations;
    unstable_batchedUpdates(() => {
      setFixedImage(fixedBitmap);
      setUnfixedImage(unfixedBitmap);
      if (reg && reg[unfixed.eye]) {
        setTransform(reg[unfixed.eye][unfixed.type]);
      }
      setFixedLookup(fixed.eye, fixed);
    });
  }

  const clearPoints = () => {
    fixedStageRef.current?.getLayers()[1].destroyChildren();
    unfixedStageRef.current?.getLayers()[1].destroyChildren();
    setFixedImageCircles([]);
    setUnfixedImageCircles([]);
    setPtsSrc([]);
    setPtsDst([]);
    setTransform({
      translation: { x: 0, y: 0 },
      scale: 1,
      rotation: 0,
    });
  };

  useEffect(() => {
    clearPoints();
  }, [fixed, unfixed]);

  const [fixedImageCircles, setFixedImageCircles] = useState<
    { x: number; y: number }[]
  >([]);
  const [unfixedImageCircles, setUnfixedImageCircles] = useState<
    { x: number; y: number }[]
  >([]);

  const addPoint = (
    e: KonvaEventObject<MouseEvent>,
    arr: number[][],
    setArr: React.Dispatch<React.SetStateAction<number[][]>>
  ) => {
    const stage = e.target.getStage();
    const stageId = stage?.attrs.id;
    const location = stage?.getPointerPosition();

    const circles = stageId === "fixed" ? fixedImageCircles : unfixedImageCircles;
    const setCircles = stageId === "fixed" ? setFixedImageCircles : setUnfixedImageCircles;

    if (location && arr.length < 3) {
      const scale: Vector2d = stage!.getLayers()[0].getChildren()[0].scale()!;
      // const point = new Circle({
      //   radius: 5,
      //   fill: "red",
      //   stroke: "black",
      //   strokeWidth: 1,
      //   x: location.x,
      //   y: location.y,
      // });
      // stage!.getLayers()[1].add(point);

      // if (stageId === "fixed") {
      setCircles([
        ...circles,
        { x: location.x / scale.x, y: location.y / scale.y },
      ]);
      // }

      // if (stageId === "unfixed") {
      //   setUnfixedImageCircles([
      //     ...unfixedImageCircles,
      //     { x: location.x / scale.x, y: location.y / scale.y },
      //   ]);
      // }

      setArr([...arr, [location.x / scale.x, location.y / scale.y]]);
    }
  };

  const updatePoint = (
    e: KonvaEventObject<MouseEvent>,
    idx: number,
  ) => {
    const stage = e.target.getStage();
    const stageId = stage?.attrs.id;
    const location = stage?.getPointerPosition();

    const arr = stageId === "fixed" ? ptsDst : ptsSrc;
    const setArr = stageId === "fixed" ? setPtsDst : setPtsSrc;


    const circles = stageId === "fixed" ? fixedImageCircles : unfixedImageCircles;
    const setCircles = stageId === "fixed" ? setFixedImageCircles : setUnfixedImageCircles;

    // get the first child of the target
    const target = e.target;
    if (!target)
      return;
    // get the relative coordinates of the mouse relative to the image
    const relativeLocation = target.getRelativePointerPosition();

    if (location && arr.length >= idx && relativeLocation) {
      // get the scale of the image
      const scale: Vector2d = stage!.getLayers()[0].getChildren()[0].scale()!;

      setCircles(prev => {
        const newCircles = [...prev];
        newCircles[idx] = {
          x: (location.x - relativeLocation.x) / scale.x,
          y: (location.y - relativeLocation.y) / scale.y,
        };
        console.log("newCircles[idx]", newCircles[idx]);
        return newCircles;
      });

      setArr(prev => {
        const newPoints = [...prev];
        newPoints[idx] = [
          (location.x - relativeLocation.x) / scale.x,
          (location.y - relativeLocation.y) / scale.y
        ];
        return newPoints;
      });
    }
  };

  const solveTransform = (
    pts_src: number[][],
    pts_dst: number[][]
  ): ImageRegistrationTransform => {
    if (pts_src.length !== pts_dst.length) {
      throw new Error("Point sets must have the same length");
    }

    // we should make sure the order of the points is the same
    let srcOrdered = [...pts_src].sort((a, b) => a[0] - b[0]);
    let dstOrdered = [...pts_dst].sort((a, b) => a[0] - b[0]);

    const pts_src_mat = new Matrix(srcOrdered);
    const pts_dst_mat = new Matrix(dstOrdered);

    const sum_src = pts_src_mat.sum("column");
    const sum_src_x = sum_src[0];
    const sum_src_y = sum_src[1];
    const sum_sq = pts_src_mat.clone().pow(2).sum();
    const N = pts_src.length;

    const A = new Matrix([
      [sum_sq, 0, sum_src_x, sum_src_y],
      [0, sum_sq, -sum_src_y, sum_src_x],
      [sum_src_x, -sum_src_y, N, 0],
      [sum_src_y, sum_src_x, 0, N],
    ]);

    const b = Matrix.columnVector([
      pts_src_mat.getColumnVector(0).dot(pts_dst_mat.getColumnVector(0)) +
      pts_src_mat.getColumnVector(1).dot(pts_dst_mat.getColumnVector(1)),
      pts_src_mat.getColumnVector(0).dot(pts_dst_mat.getColumnVector(1)) -
      pts_src_mat.getColumnVector(1).dot(pts_dst_mat.getColumnVector(0)),
      pts_dst_mat.sum("column")[0],
      pts_dst_mat.sum("column")[1],
    ]);

    const x = solve(A, b).to1DArray();

    return {
      translation: {
        x: Number(x[2].toFixed(5)),
        y: Number(x[3].toFixed(5)),
      },
      scale: Number(Math.sqrt(x[0] ** 2 + x[1] ** 2).toFixed(5)),
      rotation: Number((Math.atan2(x[1], x[0]) * (180 / Math.PI)).toFixed(5)),
    };
  };

  useEffect(() => {
    if (ptsSrc.length >= 3 && ptsSrc.length === ptsDst.length) {
      try {
        const newTransform = solveTransform(ptsSrc, ptsDst);
        setTransform(newTransform);
      } catch (error) {
        console.error("Error calculating transform:", error);
      }
    }
  }, [ptsSrc, ptsDst]);

  const blendTimeout = useRef<NodeJS.Timeout | null>(null);
  const [blendAuto, setBlendAuto] = useState(false);
  const [blendDirection, setBlendDirection] = useState(1);

  useEffect(() => {
    if (blendAuto) {
      const step = 0.05;
      let direction = blendDirection;
      blendTimeout.current = setInterval(() => {
        setOpacity((prev) => {
          if (prev + step * direction >= 1) {
            direction = -1;
          } else if (prev + step * direction <= 0) {
            direction = 1;
          }

          return prev + step * direction;
        });

        setBlendDirection(direction);
      }, 20);
    }

    return () => {
      if (blendTimeout.current) {
        clearInterval(blendTimeout.current);
      }
    };
  }, [blendAuto]);

  const handleToggleBlend = () => {
    if (blendAuto) {
      setOpacity(0.5);
    } else {
      setOpacity(Math.round(opacity / 0.05) * 0.05);
    }
    setBlendAuto(!blendAuto);
  };

  const [isDraggingPoint, setIsDraggingPoint] = useState(false);
  const [draggingPointInitialPos, setDraggingPointInitialPos] = useState<{ x: number; y: number }>({ x: 0, y: 0 });

  const handleDragStart = (e: KonvaEventObject<DragEvent>, idx: number) => {
    const stage = e.target.getStage();
    const stageId = stage?.attrs.id;
    const circles = stageId === "fixed" ? fixedImageCircles : unfixedImageCircles;
    setDraggingPointInitialPos(circles[idx]);
    setIsDraggingPoint(true);
  };

  const handleDragEnd = () => {
    setDraggingPointInitialPos({ x: 0, y: 0 });
    setIsDraggingPoint(false);
  };

  const handleDragMove = (e: KonvaEventObject<DragEvent>, idx: number) => {
    if (isDraggingPoint) {
      updatePoint(e, idx);
    }
  }


  if (!fixedImage || !unfixedImage || !fixedImageContrast || !unfixedImageContrast) {
    return <div>Loading images...</div>;
  }

  return (
    <div>
      <div>
        Register the {unfixed.type === 'FAF' ? 'FAF Field 2' : 'FAF Field 1M'} image to the Infrared (reference) image by selecting 3 corresponding points on each image.
      </div>
      <div
        className="section flex gap-y-[5px] gap-x-[5px] flex-wrap"
        onContextMenu={(e) => { e.preventDefault(); e.stopPropagation(); return false; }}
        onMouseUp={(e) => {
          if (e.button === 2) {
            setCancelContrastFixed(false);
            setCancelContrastUnfixed(false);
          }
        }}
      >
        {/* <div className="flex gap-[15px]"> */}
        < div className="flex flex-row gap-[5px]" >
          {/* fixed image points micro view */}
          <div className="flex flex-col w-[100px] h-full" >
            <div className="flex items-end text-xs leading-[28px] font-semibold text-gray-500">Points</div>
            <div className="flex flex-col gap-[5px]">
              {ptsDst.map((point, i) => (
                <ZoomedImage
                  key={i}
                  idx={i}
                  image={fixedImageContrast}
                  scale={5}
                  arr={ptsDst}
                  setArr={setPtsDst}
                  circles={fixedImageCircles}
                  setCircles={setFixedImageCircles}
                />
              ))}

              {[...Array(3 - ptsDst.length)].map((_, i) => (
                <div
                  key={i}
                  style={{ width: ZOOMED_IMAGE_SIZE, height: ZOOMED_IMAGE_SIZE }}
                  className="border-dashed border border-gray-500 flex items-center justify-center"
                >
                  <div className="text-gray-500 text-xs text-center">{i + 1}</div>
                </div>
              ))}
            </div>
          </div >
          {/* fixed image */}
          <div>
            <div className="flex items-end text-sm leading-[28px] font-semibold">Infrared (reference)</div>
            <div
              style={{
                width: IMAGE_SIZE,
                height: IMAGE_SIZE,
              }}
              onMouseDown={(e) => {
                if (e.button === 2) {
                  setCancelContrastFixed(true);
                }
              }}
              onMouseUp={(e) => {
                if (e.button === 2) {
                  setCancelContrastFixed(false);
                }
              }}
            >
              <Stage
                ref={fixedStageRef}
                id="fixed"
                width={IMAGE_SIZE}
                height={IMAGE_SIZE}
                onMouseUp={(e) => {
                  if (e.evt.button === 0 && !isDraggingPoint) {
                    addPoint(e, ptsDst, setPtsDst)
                  }
                }}
              >
                <Layer>
                  <KonvaImage
                    ref={fixedImageRef}
                    image={fixedImageContrast}
                    scaleX={IMAGE_SIZE / fixedImage.width}
                    scaleY={IMAGE_SIZE / fixedImage.height}
                  />
                </Layer>
                <Layer />
                <Layer>
                  {fixedImageCircles.map((point, i) => (
                    <Group
                      key={i}
                      draggable
                      onMouseOver={() => isDraggingPoint ? document.body.style.cursor = "default" : document.body.style.cursor = "move"}
                      onMouseUp={() => document.body.style.cursor = "pointer"}
                      onMouseDown={() => document.body.style.cursor = "none"}
                      onMouseOut={() => document.body.style.cursor = "default"}
                      onMouseLeave={() => document.body.style.cursor = "default"}
                      zIndex={2}
                      onDragStart={(e) => handleDragStart(e, i)}
                      onDragEnd={handleDragEnd}
                      onDragMove={(e) => handleDragMove(e, i)}
                      x={point.x * (IMAGE_SIZE / fixedImage.width)}
                      y={point.y * (IMAGE_SIZE / fixedImage.height)}
                    >
                      <KonvaCircle
                        key={i + "a"}
                        x={0}
                        y={0}
                        radius={10}
                        stroke={IndexToColor(i) + 99}
                        strokeWidth={2}
                      />
                      <KonvaCircle
                        key={i + "b"}
                        x={0}
                        y={0}
                        radius={1}
                        fill={IndexToColor(i)}
                        strokeWidth={0}
                      />
                      <Line
                        key={i + "c"}
                        points={[-10, 0, 10, 0]}
                        stroke={IndexToColor(i) + 66}
                        strokeWidth={1}
                      />
                      <Line
                        key={i + "d"}
                        points={[0, -10, 0, 10]}
                        stroke={IndexToColor(i) + 66}
                        strokeWidth={1}
                      />
                    </Group>
                  ))}
                </Layer>
              </Stage>
            </div>
            <div className="flex flex-col items-center pt-[10px] px-[5px] w-full">
              <Slider
                leftLabel={
                  <div className="text-sm flex gap-x-[5px] items-center">

                    <div>Contrast</div>
                    <Tooltip
                      content={
                        <>
                          Use the slider to change the contrast of the image above.<br />
                          Hold down the right mouse button while over the image to temporarily disable the contrast adjustment.<br />
                          Click the <span className="font-semibold">Reset</span> button to reset the contrast to the default value.
                        </>
                      }
                    >
                      <div>
                        <Icon icon="heroicons-outline:information-circle" className="w-[15px] h-[15px]" />
                      </div>
                    </Tooltip>
                  </div>
                }
                rightLabel={
                  <button disabled={contrastFixed === 100} className="flex gap-[5px] justify-between font-semibold border-blue-500 text-blue-500 rounded-[5px] text-xs mb-1 disabled:opacity-50"
                    onClick={() => setContrastFixed(100)}
                  >
                    <div>Reset</div>
                  </button>
                }
                min={Contrast.min} max={Contrast.max} step={Contrast.step} value={contrastFixed}
                setValue={setContrastFixed}
                valueRenderer={Contrast.valueRenderer}
                onChange={setContrastFixed}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-row gap-[5px]">
          {/* unfixed image */}
          <div>
            <div className="flex items-end text-sm leading-[28px] font-semibold">{unfixed.type === 'FAF' ? 'FAF Field 2' : 'FAF Field 1M'}</div>
            <div
              style={{
                width: IMAGE_SIZE,
                height: IMAGE_SIZE
              }}
              onMouseDown={(e) => {
                if (e.button === 2) {
                  setCancelContrastUnfixed(true);
                }
              }}
              onMouseUp={(e) => {
                if (e.button === 2) {
                  setCancelContrastUnfixed(false);
                }
              }}
            >
              <Stage
                ref={unfixedStageRef}
                id="unfixed"
                width={IMAGE_SIZE}
                height={IMAGE_SIZE}
                onMouseUp={(e) => {
                  if (e.evt.button === 0 && !isDraggingPoint) {
                    addPoint(e, ptsSrc, setPtsSrc);
                  }
                }}
              >
                <Layer>
                  <KonvaImage
                    ref={unfixedImageRef}
                    image={unfixedImageContrast}
                    scaleX={IMAGE_SIZE / unfixedImage.width}
                    scaleY={IMAGE_SIZE / unfixedImage.height}
                  />
                </Layer>
                <Layer />
                <Layer>
                  {unfixedImageCircles.map((point, i) => (
                    <Group key={i}
                      draggable
                      onMouseOver={() => isDraggingPoint ? document.body.style.cursor = "default" : document.body.style.cursor = "move"}
                      onMouseUp={() => document.body.style.cursor = "pointer"}
                      onMouseDown={() => document.body.style.cursor = "none"}
                      onMouseOut={() => document.body.style.cursor = "default"}
                      onMouseLeave={() => document.body.style.cursor = "default"}
                      x={point.x * (IMAGE_SIZE / unfixedImage.width)}
                      y={point.y * (IMAGE_SIZE / unfixedImage.height)}
                      onDragStart={(e) => handleDragStart(e, i)}
                      onDragEnd={handleDragEnd}
                      onDragMove={(e) => handleDragMove(e, i)}
                      zIndex={2}
                    >
                      <KonvaCircle
                        key={i + "a"}
                        x={0}
                        y={0}
                        radius={10}
                        stroke={IndexToColor(i) + 99}
                        strokeWidth={2}
                      />
                      <KonvaCircle
                        key={i + "b"}
                        x={0}
                        y={0}
                        radius={1}
                        fill={IndexToColor(i)}
                        strokeWidth={0}
                      />
                      <Line
                        key={i + "c"}
                        points={[-10, 0, 10, 0]}
                        stroke={IndexToColor(i) + 66}
                        strokeWidth={1}
                      />
                      <Line
                        key={i + "d"}
                        points={[0, -10, 0, 10]}
                        stroke={IndexToColor(i) + 66}
                        strokeWidth={1}
                      />
                    </Group>
                  ))}
                </Layer>
              </Stage>
            </div>
            <div className="flex flex-col items-center  pt-[10px] px-[5px] w-full">
              <Slider
                leftLabel={
                  <div className="text-sm flex gap-x-[5px] items-center">

                    <div>Contrast</div>
                    <Tooltip
                      content={
                        <>
                          Use the slider to adjust the contrast of the image above to make it easier to see the details.<br />
                          <div className="text-[10px]">
                            Hold down the right mouse button while over the image to temporarily disable the contrast adjustment.<br />
                            Click the <span className="font-semibold">Reset</span> button to reset the contrast to the default value.
                          </div>
                        </>
                      }
                    >
                      <div>
                        <Icon icon="heroicons-outline:information-circle" className="w-[15px] h-[15px]" />
                      </div>
                    </Tooltip>
                  </div>
                }
                rightLabel={
                  <button className="flex gap-[5px] justify-between font-semibold border-blue-500 text-blue-500 rounded-[5px] text-xs mb-1"
                    onClick={() => setContrastUnfixed(100)}
                  >
                    <div>Reset</div>
                  </button>
                }
                min={Contrast.min} max={Contrast.max} step={Contrast.step} value={contrastUnfixed}
                setValue={setContrastUnfixed}
                valueRenderer={Contrast.valueRenderer}
                onChange={(value) => {
                  setContrastUnfixed(value);
                }}
              />
            </div>
          </div>
          {/* unfixed image points micro view */}
          <div className="flex flex-col w-[100px] h-full">
            <div className="flex items-end text-xs leading-[28px] font-semibold text-gray-500">Points</div>
            <div className="flex flex-col gap-[5px]">
              {ptsSrc.map((point, i) => (
                <ZoomedImage
                  key={i}
                  idx={i}
                  image={unfixedImageContrast}
                  scale={5}
                  arr={ptsSrc}
                  setArr={setPtsSrc}
                  circles={unfixedImageCircles}
                  setCircles={setUnfixedImageCircles}
                />
              ))}

              {[...Array(3 - ptsSrc.length)].map((_, i) => (
                <div
                  key={i}
                  style={{ width: ZOOMED_IMAGE_SIZE, height: ZOOMED_IMAGE_SIZE }}
                  className="border-dashed border border-gray-500 flex items-center justify-center"
                >
                  <div className="text-gray-500 text-xs text-center">{ptsSrc.length + i + 1}</div>
                </div>
              ))}

            </div>
          </div>
        </div>
        {/* </div> */}
        <div className="flex flex-col pl-[105px]">
          {/* superimposed Image */}
          <div>
            <div className="flex gap-x-[5px] items-center">
              <div className="flex items-end text-sm leading-[28px] font-semibold">Superimposed Image</div>
              <Tooltip
                content={
                  <>
                    The Superimposed Image is a blend of the two images.<br />
                    <span className="text-xs">
                      Use the slider below to control the amount of blending between the two images<br />
                      You can use the <span className="font-medium">Auto Blend</span> button to automatically blend between the two images
                    </span>
                  </>
                }
              >
                <div>
                  <Icon icon="heroicons-outline:information-circle" className="w-[15px] h-[15px]" />
                </div>
              </Tooltip>
            </div>
            <div style={{
              width: IMAGE_SIZE,
              height: IMAGE_SIZE
            }}>

              <Stage
                width={IMAGE_SIZE}
                height={IMAGE_SIZE}
                scaleX={IMAGE_SIZE / fixedImage.width}
                scaleY={IMAGE_SIZE / fixedImage.height}
              >
                <Layer>
                  <KonvaImage image={fixedImage} />
                  <KonvaImage
                    image={unfixedImage}
                    opacity={opacity}
                    x={transform.translation.x}
                    y={transform.translation.y}
                    scaleX={transform.scale}
                    scaleY={transform.scale}
                    rotation={transform.rotation}
                  />
                </Layer>
              </Stage>
            </div>
            <div className="flex flex-col items-center pt-[10px] px-[5px] w-full">
              <Slider
                leftLabel={
                  <div className="text-sm flex gap-x-[5px] items-center">

                    <div>Blending Control</div>
                    <Tooltip content="Use the slider to control the amount of blending between the two images in the view above">
                      <div>
                        <Icon icon="heroicons-outline:information-circle" className="w-[15px] h-[15px]" />
                      </div>
                    </Tooltip>
                  </div>
                }

                rightLabel={
                  !blendAuto ?
                    (
                      <button className="min-w-[115px] flex justify-between font-semibold border-blue-500 text-blue-500 rounded-[5px] text-xs mb-1"
                        onClick={handleToggleBlend}
                      >
                        <Icon icon="heroicons:play-solid" className="w-[15px] h-[15px]" />
                        <div>Start Auto Blend</div>
                      </button>
                    ) :
                    (
                      <button className="min-w-[115px] flex justify-between font-semibold border-red-500 text-red-500 rounded-[5px] text-xs mb-1"
                        onClick={handleToggleBlend}
                      >
                        <Icon icon="heroicons:stop-solid" className="w-[15px] h-[15px]" />
                        <div>Stop Auto Blend</div>
                      </button>
                    )
                }
                min={0} max={1} step={0.01} value={opacity}
                setValue={setOpacity}
                valueRenderer={(value) => (
                  <div className="w-full flex justify-between text-xs pt-0.5">
                    <div className="flex gap-x-[3px]">
                      <div className="font-medium">Infrared (reference)</div>
                      <div style={{
                        opacity: Math.max(1 - value, 0.2),
                      }}>{`${((1 - value) * 100).toFixed(0)}%`}</div>
                    </div>
                    <div className="flex gap-x-[3px]">
                      <div
                        style={{
                          opacity: Math.max(value, 0.2),
                        }}
                      >{`${((value) * 100).toFixed(0)}%`}</div>
                      <div className="font-medium">{unfixed.type === 'FAF' ? 'FAF Field 2' : 'FAF Field 1M'}</div>
                    </div>

                  </div>
                )}
                onChange={setOpacity}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


type SliderProps = {
  leftLabel?: string | React.ReactNode;
  rightLabel?: string | React.ReactNode;
  min: number;
  max: number;
  value: number;
  setValue: (value: number) => void;
  valueRenderer?: (value: number) => string | React.ReactNode;
  onChange: (value: number) => void;
  step: number;
  disabled?: boolean;
  id?: string;
  name?: string;
  'aria-label'?: string;
  className?: string;
};

export const Slider = ({
  min,
  max,
  value,
  valueRenderer,
  onChange,
  leftLabel,
  rightLabel,
  step = 1,
  disabled = false,
  id,
  name,
  className,
  'aria-label': ariaLabel,
}: SliderProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  // Ensure the value is within bounds and snapped to step
  const normalizeValue = useCallback((val: number) => {
    const clampedValue = Math.min(max, Math.max(min, val));
    if (step) {
      const steps = Math.round((clampedValue - min) / step);
      return min + (steps * step);
    }
    return clampedValue;
  }, [min, max, step]);

  // Convert pixel position to value
  const getValueFromPosition = useCallback((positionX: number) => {
    if (!containerRef.current) return value;

    const rect = containerRef.current.getBoundingClientRect();
    const percentage = Math.max(0, Math.min(1, (positionX - rect.left) / rect.width));
    const newValue = min + percentage * (max - min);
    return normalizeValue(newValue);
  }, [min, max, value, normalizeValue]);

  // Handle mouse movement during drag
  const handleDrag = useCallback((e: MouseEvent) => {
    if (!isDragging || disabled) return;
    const newValue = getValueFromPosition(e.clientX);
    onChange(newValue);
  }, [isDragging, onChange, getValueFromPosition, disabled]);

  // Handle mouse up - stop dragging
  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
  }, []);

  // Handle mouse leave - stop dragging if mouse leaves window
  const handleMouseLeave = useCallback(() => {
    if (isDragging) {
      setIsDragging(false);
    }
  }, [isDragging]);

  // Handle click on the slider
  const handleMouseDown = (e: React.MouseEvent) => {
    if (disabled) return;
    setIsDragging(true);
    const newValue = getValueFromPosition(e.clientX);
    onChange(newValue);
  };

  // Handle keyboard navigation
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (disabled) return;

    let newValue = value;
    const smallStep = step;
    const largeStep = Math.max((max - min) / 10, step);

    switch (e.key) {
      case 'ArrowRight':
      case 'ArrowUp':
        newValue = normalizeValue(value + (e.shiftKey ? largeStep : smallStep));
        break;
      case 'ArrowLeft':
      case 'ArrowDown':
        newValue = normalizeValue(value - (e.shiftKey ? largeStep : smallStep));
        break;
      case 'Home':
        newValue = min;
        break;
      case 'End':
        newValue = max;
        break;
      default:
        return;
    }

    e.preventDefault();
    onChange(newValue);
  };

  useEffect(() => {
    if (isDragging) {
      window.addEventListener('mousemove', handleDrag);
      window.addEventListener('mouseup', handleMouseUp);
      window.addEventListener('mouseleave', handleMouseLeave);

      return () => {
        window.removeEventListener('mousemove', handleDrag);
        window.removeEventListener('mouseup', handleMouseUp);
        window.removeEventListener('mouseleave', handleMouseLeave);
      };
    }
  }, [isDragging, handleDrag, handleMouseUp, handleMouseLeave]);

  // Prevent text selection during drag
  useEffect(() => {
    if (isDragging) {
      // Add a class to the body that prevents text selection
      document.body.classList.add('select-none');

      return () => {
        document.body.classList.remove('select-none');
      };
    }
  }, [isDragging]);

  const percentage = ((value - min) / (max - min)) * 100;

  return (
    <div className={`w-full flex flex-col ${className || ''}`}>
      {(leftLabel || rightLabel) && (
        <div className="flex justify-between items-end pb-0.5text-md font-semibold">
          {leftLabel && <div>{leftLabel}</div>}
          {rightLabel && <div>{rightLabel}</div>}
        </div>
      )}
      <div
        ref={containerRef}
        className={`relative h-[15px] rounded-sm cursor-pointer ${disabled ? 'bg-gray-300 cursor-not-allowed' : 'bg-gray-300'
          }`}
        onMouseDown={handleMouseDown}
      >
        <div
          role="slider"
          tabIndex={disabled ? -1 : 0}
          aria-valuemin={min}
          aria-valuemax={max}
          aria-valuenow={value}
          aria-label={ariaLabel}
          aria-disabled={disabled}
          id={id}
          className={`absolute h-full rounded-l-sm ${disabled ? 'bg-gray-400' : 'bg-blue-500'
            }`}
          style={{ width: `${percentage}%` }}
          onKeyDown={handleKeyDown}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
        {/* <div
          className={`absolute w-4 h-4 -mt-1 -ml-2 rounded-full shadow transition-transform ${disabled
            ? 'bg-gray-400 border-gray-300'
            : `bg-white border-blue-500 ${isDragging || isFocused ? 'scale-110' : ''}`
            } border-2`}
          style={{ left: `${percentage}%` }}
        /> */}
      </div>
      {valueRenderer && (
        <>
          {valueRenderer(value)}
        </>
      )}
    </div>
  );
}

// useContrastImage accepts an imageBitmap
export const useContrastImage = ({
  image,
}: {
  image: ImageBitmap | null;
}) => {
  const imageRef = useRef<Konva.Image>(null);
  const [imageBitmap, setImageBitmap] = useState<ImageBitmap | null>(null);

  const [contrast, setContrast] = useState(100);
  const [cancelContrast, setCancelContrast] = useState(false);

  useEffect(() => {
    if (image) {
      if (contrast === 100 || cancelContrast) {
        setImageBitmap(image);
        return;
      }

      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      if (ctx) {
        canvas.width = image.width;
        canvas.height = image.height;
        ctx.filter = `contrast(${contrast}%)`;
        ctx.drawImage(image, 0, 0);
        createImageBitmap(canvas).then((newImage) => {
          setImageBitmap(newImage);
        });
      }
    }
  }, [image, contrast, cancelContrast]);

  return { imageBitmap, imageRef, contrast, setContrast, setCancelContrast };
}

